<template>
  <b-sidebar
    :id="uuid"
    v-if="agent"
    bg-variant="white"
    right
    :width="sidebarWidth()"
    backdrop
    shadow
    class="agent-sidebar"
    lazy
    @hidden="$emit('agentClosed')"
    @shown="hasSaved=false"
    @change="sidebarStateChange"
  >
    <template #header class="bg-default">
      <div class="d-flex justify-content-between align-items-center w-100">
      
        <h3 class="m-0">{{ $t(`agent.list.${i18nKey}.title`) }}</h3>
        
        <b-button variant="none" class="p-25">
          <b-icon
            icon="x"
            scale="1.5"
            variant="secondary"
            class="cursor-pointer mt-25"
            @click="toggleSidebar"
          />

        </b-button>

      </div>

    </template>
    <template #default >
      <div class="sidebar-container">
        <b-container fluid class="sidebar-fixed-header">
          <variables-panel
            :ref="getID('variables-panel')"
            :middleware_id="middlewareID"
            @loaded="configureVars"
            :headerExpandedAtInit="!agentItemInEnum.closedVariablesPannelInit"
          >
            <template #header>
              <b-link class="wiki-link" exact-path target="_blank" href="https://fiqon.readme.io/docs/delete">
                {{ $t("agent.wiki_prompt") }} {{ $t(`agent.list.${i18nKey}.title`) }}?
              </b-link>

              <p class="text-muted">
                {{ $t(`agent.list.${i18nKey}.description`) }}
              </p>

            </template>
          </variables-panel>
        </b-container>
        <vue-perfect-scrollbar class="sidebar-content">
          <div class="px-75">
            
            <template v-if="component">
              <component
                class="w-100"
                ref="inner-agent-component"
                :is="component"
                :agentID="agentID"
                :uuid="uuid"
                :middlewareID="middlewareID"
                v-model="agent"
                :agentBlockID="agentBlockID"
                :isDebugMode="isDebugMode"
                :variables="variables"
                @saved="onSaved"
                @save="save()"
              />
            </template>

          </div>
        </vue-perfect-scrollbar>

        <b-modal
          ref="confirm-close-modal"
          centered
          ok-variant="favorite"
          title="You have unsaved changes to this Agent"
          cancel-variant="outline-secondary"
          auto-focus-button="ok"
          @ok="allowForceClose = true; toggleSidebar()"
        >
          <div>
            Discard changes and close anyways?
          </div>

          <template #modal-ok>
            Close
          </template>
        </b-modal>

      </div>
    </template>
    <template #footer>
      <b-row class="mb-75">
        <b-col md="12" class="d-flex justify-content-end">
          <b-button
            block
            variant="primary"
            class="mx-2"
            @click="save"
            :disabled="isSaving"
          >
            <div v-if="isSaving" class="d-flex align-items-center justify-content-center">
              <b-spinner small/>
              <span class=" d-inline-block ml-1">
                {{$t("common.terms.saving")}}
              </span>
            </div>
            <span v-else>{{ $t("common.terms.save") }}</span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-sidebar>
</template>

<script>


import {
  BLink,
  BSkeleton, 
  BFormCheckbox, 
  BButton, 
  BContainer, 
  BCol, 
  BRow, 
  BSidebar, 
  BIcon ,
  BSpinner,
} from "bootstrap-vue";import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Agents from "@/custom/class/Enum/Agents.js"
import ListAgents from '@/layouts/components/Transmission/Middleware/Agent/List'

import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

  components: {
    ...ListAgents,
    BButton,
    BContainer,
    BCol,
    BRow,
    BSidebar,
    VariablesPanel,
    CustomInput,
    BFormCheckbox,
    BIcon,
    BSkeleton,
    HelperTooltip,
    BLink,
    VuePerfectScrollbar,
    BSpinner,
  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    agentID: {
      type: Number,
      default: undefined,
    },
    value: {
      type: [NormalizedAgent, Array],
      default: undefined
    },
    uuid: {
      type: String,
      default: uuidv4(),
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1,
    },
    width: {
      type: [String, Number],
      default: undefined,
    },
    component:{
      type: [ String ],
      default: undefined,
    },
    isDebugMode:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      agentItemInEnum: new Agents().items.find(el => el.component == this.component),
      actionID: 1,
      i18nKey: '',
      startData: undefined,
      allowForceClose: false, 
      defaultAgent: {
        id: null,
        enum_agent_action_id: this.actionID,
        agent_block_id: this.agentBlockID,
        register_1: {
          source: 7,
          value: ""
        },
        register_2: {
          source: 7,
          value: ""
        },
        register_destiny: {
          source: 10,//--DEFINE--
          value: ""
        },
        execution_order: 1,
        fatal_on_fail: true,
      },
      
      uuidMap: {},

      variant_map: null,
      variables: null,

      isSaving: false,
      hasSaved: false,
    };
  },
  computed: {
    agent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    toSaveRegs() {
      return this.$refs['inner-agent-component'].toSaveRegs
    },
  },
  beforeMount(){
    this.actionID = this.agentItemInEnum.id
    this.i18nKey = this.agentItemInEnum.i18Key
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
    init() {
      DefaultAgent.fetchAgentByID(this)
      this.cloneStart()
    },
    cloneStart(){
      this.startData = JSON.stringify(structuredClone(this.agent))
    },
    save() {
      if (!this.hasSaved){
      
        if ( typeof this.$refs['inner-agent-component']?.beforeSave == 'function' ){
          let canSave = this.$refs['inner-agent-component'].beforeSave()
          if ( !(canSave == null || canSave == true) ){
            return
          }
        }

        this.hasSaved = true;
        return DefaultAgent.save(this)
      }
    },
    validate() {
      return DefaultAgent.validate(this)
    },
    configureVars() {
      this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
      this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }
      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
    onSaved(payload, toggleSidebar=true){
      this.$emit('saved', payload, toggleSidebar)
    },
    sidebarWidth(){
      if (window.innerWidth < 1400){
        return "760px";
      }
      if (this.width){
        return this.width
      } else if (this.agentItemInEnum.width){
        return this.agentItemInEnum.width
      } else {
        return "40%"
      }
    },
    sidebarStateChange(state){
      if (state == false){
        
        if (!this.hasSaved && !this.allowForceClose &&  JSON.stringify(this.agent) != this.startData){
          this.toggleSidebar()
          this.$refs['confirm-close-modal'].show()
          return
        }

        if (this.allowForceClose && !this.hasSaved){
          this.agent = new NormalizedAgent( JSON.parse(this.startData) )
        }

        this.$emit('agentClosed')
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.sidebar-container{
  max-height: 97vh;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}

</style>
