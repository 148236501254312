<template>
  <div>
    <div :key="`service-list`">
      <div class="m-50" v-if="loadingGroups">
        <div class="d-flex justify-content-between">
          <b-skeleton height="40px" width="50px"/>
          <b-skeleton height="40px" width="120px"/>
          <b-skeleton height="40px" width="50px"/>
        </div>
        <b-skeleton
          v-for="i in Math.max(8, Math.floor(12 * Math.random()))" :key="`body-skeleton-${i}`"
          height="40px"
          class="mt-50"
          width="100%"
          />
      </div>
      <div v-else-if="!selectedService">
        <div class="mt-75 mb-75 text-info d-flex justify-content-between">
          <div/>
          <div
            class="mr-1 cursor-pointer"
            @click="fetchInstalledServices()"
          >
            <b-icon
              :animation="refreshList ? 'spin' : ''"
              icon="arrow-repeat"
              variant="info"
              scale="1.3"
            />
            <small class="font-weight-bold">
              Refresh
            </small>
          </div>
        </div>
        <b-collapse appear :visible="!refreshList">
          <li class="enum-middleware-list cursor-pointer mx-50 mt-0 mb-75" v-for="service in filteredServices" :key="service.release.id" @click="loadSelectedService(service)">
            <div class="d-flex align-items-center border rounded p-50">
              <div class="" style="z-index: 5" align="right" v-if="service.favorite">
                <b-iconstack
                  class="position-absolute mr-25"
                  shift-h="-3"
                  shift-v="12"
                >
                  <b-icon
                    shift-v="23"
                    icon="bookmark-fill"
                    variant="purple"
                    scale="1.3"
                  />
                  <b-icon
                    shift-v="18"
                    icon="bookmark-fill"
                    variant="purple"
                    style="opacity: 0.7"
                    scale="1.3"
                  />
                </b-iconstack>
              </div>
              <b-avatar
                class="rounded mr-50"
                size="40px"
                :src="`https://robohash.org/${service.id}`" 
              />
              <div class="w-100 d-flex align-contentcenter">
                <b>
                  {{ $t(service.name) }}
                </b>
                <small class="text-muted mx-50">
                  {{ service.release.label }}
                </small>
              </div>
              <!-- <b-badge variant="" class="float-right" style="font-size: 10px;"> {{ $t(service.release.enum_release_tag.label) }}</b-badge> -->
              
              <div class="service-category-badge" :id="getID(service.release.id)">
                <b-badge variant="light-purple" class="rounded-pill">
                  {{ service.integrator_category.label}}
                </b-badge>
                <b-tooltip :key="getID(service.release.id)" :target="getID(service.release.id)">
                  <span>Category</span>
                </b-tooltip>
              </div>
            </div>
          </li>
        </b-collapse>
        <b-collapse :visible="refreshList" appear>
          <div>
            <b-skeleton height="55px" class="mx-50" v-for="i in 10" :key="'refresh-bskeleton-'+i" animation="fade"/>
          </div>
        </b-collapse>
          <li class="enum-middleware-list cursor-pointer m-50 pb-2 text-center">
            <b-link class="w-100 p-50" target="_blank" href="/integrator/service">
              <small class="mt-50 ml-50">
                Not enough services? Add more here
              </small>
              <b-icon
                class="mt-50"
                icon="plus"
                variant="success"
                scale="1.15"
                shift-v="-1"
                shift-h="-3"
              />
            </b-link>
          </li>
      </div>
      <div v-else>
        <div class="d-flex justify-content-between">
          <div class="pl-25 pr-75 py-75 cursor-pointer" @click="selectedService = null">
            <b-icon
              class="ml-1"
              icon="arrow-left-short"
              variant="success"
              scale="2.5"
              
            />
          </div>
          <div class="d-flex align-items-center">
            <b-avatar
              class="rounded mr-50 mt-25"
              size="30px"
              :src="`https://robohash.org/${selectedService.service.id}`"
            />
            <div>
              <p class="font-weight-bolder mb-0 mt-50 p-0">{{ selectedService.service.name }}</p>
              <b-link class="font-weight-lighter mt-0" style="font-size: 11px">{{ selectedService.service.docs}}</b-link>
            </div>
          </div>
          <div class="px-75 py-75 cursor-pointer" @click="toggleGroups(false)">
            <b-icon
              icon="folder-minus"
              class="mr-1"
              variant="light-gray"
              scale="1.3"
            />
          </div>
        </div>
        <li class="enum-middleware-list cursor-pointer m-50" v-for="(group, key, index) in releaseGroups" :key="`release-group-${key}-${index}`">
          <div class="mb-50" @click="setCollapsedGroup(index)">
            <div class="d-flex justify-content-between align-items-center border rounded p-75">
              <div>
                <b-icon
                  :icon="collapsedGroup[index] ? 'folder2-open' : 'folder2'"
                  scale="1.5"
                  class="mr-50"
                />
                <b>
                  {{ key }}
                </b>
              </div>
              <div>
                <small class="font-weight-light mr-50 text-secondary">
                  {{ group.requests ? Object.keys(group.requests).length : 0 }} Items
                </small>
                <b-icon
                  :icon="collapsedGroup[index] ? 'chevron-up' : 'chevron-down'"
                />
              </div>
            </div>
          </div>
          <b-collapse v-model="collapsedGroup[index]" :key="`collapse-group-${key}-${index}`">
            <div v-for="(request, pos, innerIndex) in group.requests" :key="`release-request-${request.name}-${innerIndex}`" class="d-flex justify-content-between">
              <div class="w-10 vertical-line" >
              </div>
              <drag
                class="w-100"
                :transferData="{ item: request, service: serviceInfo, originID }"
              >
                <div class="border rounded p-50 my-25 pl-75 d-flex justify-content-between draggable-item">
                  <div>
                    <b-iconstack font-scale="1.17" style="opacity: 0.4" class="mr-25">
                      <b-icon
                        shift-h="-5"
                        icon="three-dots-vertical"
                      />
                      <b-icon
                        icon="three-dots-vertical"
                      />
                    </b-iconstack>
                    <b>
                      {{ request.name }}
                    </b>
                  </div>
                  <div>
                    <b-badge :variant="getRequestMethod(request.enum_metodo.id || request.enum_metodo, 'variant')" class="mr-50">
                      <small>
                        {{ getRequestMethod(request.enum_metodo.id || request.enum_metodo, 'label') }}
                      </small>
                    </b-badge>
                    <b-icon
                      variant="secondary"
                      icon="question-circle"
                    />
                  </div>
                </div>
              </drag>
            </div>
          </b-collapse>
        </li>
        <div class="text-center d-flex align-items-center p-3" style="height: 150px">
          <h4 v-if="!releaseGroups" class="font-weight-bold text-secondary">
            Oops! This service doesn't have any groups yet. 😓
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BLink,
  BCollapse,
  BBadge,
  BTooltip,
  BSkeleton
} from 'bootstrap-vue'
import Drag from '@/views/pages/middleware/MiddlewareManagement/Components/Drag.vue'
import { mapGetters } from 'vuex'
import { makeToast } from '@/layouts/components/Popups';
import Method from '@/custom/class/Integrator/Method.js'
import ReleaseTags from '@/custom/class/Enum/ReleaseTags.js'
import { v4 as uuidv4 } from 'uuid'
export default {
    components: {
      Drag,
      BAvatar,
      BLink,
      BCollapse,
      BBadge,
      BTooltip,
      BSkeleton
    },
    props: {
    },
    data() {
      return {
        refreshList: false,
        originID: 2,
        selectedService: null,
        releaseGroups: null,
        releaseInfo: null,
        collapsedGroup: new Object(),
        term: '',
        uuidMap: new Object(),
        loadingGroups: false
      }
    },
    computed: {
      ...mapGetters('releaseModule', ['getReleaseInfo']),
      ...mapGetters('serviceModule', ['getInstalledServices', 'getServicesByOrg']),
      services() {
        return Object.keys(this.getInstalledServices).length ? this.getInstalledServices : new Array()
      },
      filteredServices() {
        let serv = structuredClone(this.services)
        if (this.term) {
          return serv.filter(el =>
            {
              return (
                el.name.toLowerCase().indexOf(this.term.toLowerCase()) > -1
                ||
                el.host.toLowerCase().indexOf(this.term.toLowerCase()) > -1
              )
            }
          )
        }
        return serv
      },
      organizationID() {
        return localStorage.getItem('selectedOrganization')
      },
      serviceInfo() {
        if (!this.selectedService) return
        
        let {
          id, 
          name,
          identifier,
          host,
          description,
          docs,
          avatar,
        } = this.selectedService.service

        return {
          id, 
          name,
          identifier,
          host,
          description,
          docs,
          avatar,
        } 
      }
    },
    mounted () {
      this.init();
    },
    watch: {
      organizationID(newValue, oldValue) {
        console.log('%c'+JSON.stringify(newValue), 'color: limegreen'); 
      }
    },
    methods: {
      init() {
        if (!Object.keys(this.services).length) {
          this.fetchInstalledServices()
        }
      },
      fetchInstalledServices() {
        this.refreshList = true
        this.$store.dispatch('serviceModule/fetchInstalledServices', { organizationID: this.organizationID })
          .then(() => {
            this.refreshList = false
            this.version++
          }).catch((error) => {
            console.error(error)
            this.refreshList = false
          })
      },
      loadSelectedService(service) {
        this.fetchServiceRequests(service.release.id)
      },
      fetchServiceRequests(releaseID) {
        this.loadingGroups = true
        this.$store.dispatch('releaseModule/getReleaseByID', { releaseID, toFlux: true }).then((resp) => {
          this.selectedService = resp
          this.selectedService = JSON.parse(resp.script)
          this.releaseGroups = this.selectedService.service.groups
          this.loadingGroups = false
        }).catch((err) => {
          this.loadingGroups = false
          makeToast({
            title: "Error while loading service groups",
            variant: 'danger',
            icon: "XIcon"
          })
        })
      },
      setCollapsedGroup(index) {
        this.$set(this.collapsedGroup, index, !this.collapsedGroup[index])
      },
      toggleGroups(state) {
        Object.keys(this.releaseGroups).forEach((_, index) => {
          this.$set(this.collapsedGroup, index, state)
        })
      },
      getRequestMethod(enumID, type = null) {
        let method = new Method(enumID)

        return method[type] || method
      },
      search(term) {
        this.term = term
      },
      getEnumReleaseTag(id, field = null) {
        console.log(id)
        return field ? new ReleaseTags().items.find(el => el.id == id)[field] : new ReleaseTags().items.find(el => el.id == id) 
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key]
        }

        const uuid = uuidv4()
        this.uuidMap[key] = uuid

        return uuid
      },
    },
  }
</script>

<style lang="scss" scoped>
.draggable-item{
  transition: all 0.2s;
  cursor: grab;
  outline: 1px solid transparent;
  &:hover{
    outline: 1px solid rgba(255, 255, 255, 0.2);
  }
}
.enum-middleware-list {
  list-style-type: none;
  margin: 5px 0 5px 0;
}
.vertical-line {
  border-left: 3px solid var(--success);
  margin: 0 2% 0 2%; 
  opacity: .4;
}
.service-category-badge {
  position: relative;
  //bottom: -12px;
  //right: -4px;

}
</style>