<template>
  <div class="" >
    <div v-if="shownScript && shownScript.permissions && shownScript.permissions">
      <div class="position-relative" :class="isRealTime ? 'real-time' : ''">
        <b-row no-gutters>
          <b-col cols="12" class="">
            <!-- ----------------------------------------------------------------- -->
            <flickity ref="flickity" :options="{initialIndex: 1, prevNextButtons: true, pageDots: false, wrapAround: false, adaptiveHeight: true,}" class="px-01">
              <div v-for="(evt, index) in Object.keys(shownScript.permissions) " :key="'evt-map-'+index" class="py-1" >
                <div class="d-flex align-items-start" :class="isRealTime ? '': 'maxed-height-in-modal'">
                <div class="visualization-map-container my-2 position-relative d-flex align-items-center justify-content-center flex-wrap mx-1">

                  <h4 class="map-title">
                    {{evt}}
                    <b-icon icon="broadcast-pin" class="ml-50"/>
                  </h4>
                  <div class="middle-divider"></div>

                  <div class="position-relative w-100 m-0 ">
                    <execution-order class="helper-position"/>
                  </div>


                  <div class="w-100 mb-4">
                    <div class="d-flex  w-100 justify-content-evenly">
                      <div class="in-out-label color-in " align="center">
                        <h3 class="">
                          <span class="">IN</span>
                        </h3>
                      </div>
                      <div class=" in-out-label color-out" align="center">
                        <h3 class="">
                          <span class=""> OUT</span>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <!-- //////////////////// -->

                  <div class="position-relative centering-container align-items-center flex-wrap w-100">
                    <div class="d-flex flex-wrap align-items-center justify-content-end position-relative w-37 aligned-to-middle">
                      <div>
                        <div v-for="(serviceIn, index) in shownScript.permissions[evt].can_send" :key="'svc-in-'+index" class="d-flex align-items-center justify-content-end">
                          <h4 class="p-50 service-block">
                            <span class="service-name">{{ serviceIn }}</span>
                            <h5 class="middleware-number-indicator middleware-tooltip-indicator left color-in" v-if="getNumOfMiddlewaresFrom(serviceIn, null, false)" :class="numberIndecatorClass(serviceIn, null, false)"  @click="showMiddlewares(serviceIn, null, false)">
                              <span class="visible-label">{{ getNumOfMiddlewaresFrom(serviceIn, null, false) }}</span>
                              <span class="hidden-label"> Middlewares - <b>Service (IN)</b></span>
                            </h5>
                            
                            <span class="category-label right">service</span>
                          </h4> 
                          <span class="connection-point"  @click="navToEmptyCategory(serviceIn, null, false)" :class="noMiddlewareIndicatorClass(serviceIn, null, false)"/>

                          <span class="connection-line" :class="isLastOrFirst(index, shownScript.permissions[evt].can_send.length) ? '' : 'in-middle'"/>
                          
                          <div class="position-relative">
                            <h5 class="middleware-number-indicator middleware-tooltip-indicator right mx-2 mt-25 color-in" v-if="getNumOfMiddlewaresFrom(serviceIn, evt, false)" :class="numberIndecatorClass(serviceIn, evt, false)" @click="showMiddlewares(serviceIn, evt, false)">
                              <span class="visible-label">{{ getNumOfMiddlewaresFrom(serviceIn, evt, false) }} </span>
                              <span class="hidden-label"> Middlewares: <b>Service + Event (IN)</b></span>
                            </h5>
                            <span v-else class="no-middleware-indicator right" @click="navToEmptyCategory(serviceIn, evt, false)" :class="noMiddlewareIndicatorClass(serviceIn, evt, false)"/>
                          </div>
                        </div>
                      </div>
                      
                      <div class="vertical-connector-line left" v-if="shownScript.permissions[evt].can_send.length>1">
                        <div/>
                      </div>
                      <div class="mr-negative" v-else/>
                      <div class="px-1"/>
                    </div>


                    <!-- ============================================================= -->
                    <div class="position-relative w-25 box">
                      <div class="text-light d-flex justify-content-between align-items-center center-event-container w-100">
                        
                        <div class="d-flex justify-content-center align-items-center">
                          
                          <div class="d-flex justify-content-center flex-wrap align-items-center">
                            <div class="w-100 text-center category-label-static solid-bg">event</div>
                            <h4 class="d-block text-center mb-1 p-50 event-block w-100 solid-bg "> <b>{{evt}}</b></h4>

                            <div class="d-flex  justify-content-between align-items-center w-100" >
                                              
                              <span class="connection-line" :class="shownScript.permissions[evt].can_send.length>0 ? '' : 'invisible'"/>                    
                              
                              <span class=" position-relative mt-25 "  v-if="shownScript.permissions[evt].can_send.length">
                                <h5 class="middleware-number-indicator middleware-tooltip-indicator right color-in" v-if="getNumOfMiddlewaresFrom(null, evt, false)" @click="showMiddlewares(null, evt, false)" :class="numberIndecatorClass(null, evt, false)">
                                  <span class="visible-label">{{ getNumOfMiddlewaresFrom(null, evt, false) }} </span>
                                  <span class="hidden-label"> Middlewares: <b>Event (IN)</b> </span>
                                </h5>
                                <span v-else class="no-middleware-indicator left negative-mt"  @click="navToEmptyCategory(null, evt, false)" :class="noMiddlewareIndicatorClass(null, evt, false)"/>
                              </span>
                              
                              <fiqon-logo :size="60" class="pt-75 m-0 w-100 fiqon-logo"/>
                              
                                
                              <span class=" position-relative mt-25" v-if="shownScript.permissions[evt].can_receive.length">
                                <h5 class="middleware-number-indicator middleware-tooltip-indicator left color-out" v-if="getNumOfMiddlewaresFrom(null, evt, true)" @click="showMiddlewares(null, evt, true)" :class="numberIndecatorClass(null, evt, true)">
                                  <span class="visible-label">{{ getNumOfMiddlewaresFrom(null, evt, true) }} </span>
                                  <span class="hidden-label"> Middlewares: <b>Event (OUT)</b> </span>
                                </h5>
                                <span v-else class="no-middleware-indicator right negative-mt"  @click="navToEmptyCategory(null, evt, true)" :class="noMiddlewareIndicatorClass(null, evt, true)"/>
                              </span>
                              <span class="connection-line" :class="shownScript.permissions[evt].can_receive.length>0 ? '' : 'invisible'"/>
                            </div>

                            <!-- invisible duplicates to maintain the logo on vertical center -->
                            <h4 class="d-block text-center mb-1 p-50 event-block invisible w-100"> <b>{{evt}}</b></h4>
                            <div class="w-100 text-center category-label-static invisible">event</div>
                            <!--  -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ============================================================= -->

                    <div class="d-flex flex-wrap align-items-center justify-content-start position-relative w-37 aligned-to-middle" >
                      
                      <div class="px-1"/>
                      <div class="vertical-connector-line right" v-if="shownScript.permissions[evt].can_receive.length>1">
                        <div/>
                      </div>
                      <div v-else class="ml-negative"/>

                      <div >
                        <div v-for="(serviceOut, index) in shownScript.permissions[evt].can_receive" :key="'svc-in-'+index" class="d-flex align-items-center justify-content-start ">
                          
                          <div class="position-relative ">
                            <h5 class="middleware-number-indicator middleware-tooltip-indicator left mt-25 mx-2 color-out" v-if="getNumOfMiddlewaresFrom(serviceOut, evt, true)"  @click="showMiddlewares(serviceOut, evt, true)" :class="numberIndecatorClass(serviceOut, evt, true)">
                              <span class="visible-label">{{ getNumOfMiddlewaresFrom(serviceOut, evt, true) }}</span>
                              <span class="hidden-label"> Middlewares: <b>Service + Event (OUT)</b></span>
                            </h5>
                            <span v-else class="no-middleware-indicator left"  @click="navToEmptyCategory(serviceOut, evt, true)" :class="noMiddlewareIndicatorClass(serviceOut, evt, true)"/>
                          </div>

                          <span class="connection-line" :class="isLastOrFirst(index, shownScript.permissions[evt].can_receive.length) ? '' : 'in-middle'"/>

                          <span class="connection-point" @click="navToEmptyCategory(serviceOut, null, true)" :class="noMiddlewareIndicatorClass(serviceOut, null, true)"/>

                          <h4 class="p-50 service-block ">
                            <span class="service-name">{{ serviceOut }}</span>
                            <h5 class="middleware-number-indicator middleware-tooltip-indicator right color-out" v-if="getNumOfMiddlewaresFrom(serviceOut, null, true)" @click="showMiddlewares(serviceOut, null, true)" :class="numberIndecatorClass(serviceOut, null, true)">
                              <span class="visible-label">{{ getNumOfMiddlewaresFrom(serviceOut, null, true) }}</span>
                              <span class="hidden-label"> Middlewares: <b>Service (OUT)</b></span>
                            </h5>
                            <span class="category-label left">service</span>
                          </h4> 
                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between w-100 px-3 mt-50">
                    
                    <h4 class="p-50  free-block" v-if="getNumOfMiddlewaresFrom(null, null, false)">
                      FREE
                      <h5 class="middleware-number-indicator middleware-tooltip-indicator left color-in" @click="showMiddlewares(null, null, false)" :class="numberIndecatorClass(null, null, false)">

                        <span class="visible-label">{{ getNumOfMiddlewaresFrom(null, null, false) }}</span>
                        <span class="hidden-label"> Mids. -wada IN</span>
                      </h5>
                    </h4> 
                    <span v-else>
                      <div v-if="isRealTime" class="d-flex align-items-center position-relative">
                        <span class="position-relative">
                          <span class="connection-point free"  @click="navToEmptyCategory(null, null, false)" :class="noMiddlewareIndicatorClass(null, null, false)"/>
                        </span>
                        <h4 class="p-50  free-block ">FREE</h4>
                      </div>
                    </span>

                    <h4 class="p-50  free-block" v-if="getNumOfMiddlewaresFrom(null, null, true)">
                      FREE
                      <h5 class="middleware-number-indicator middleware-tooltip-indicator right color-out" @click="showMiddlewares(null, null, true)" :class="numberIndecatorClass(null, null, true)">

                        <span class="visible-label">{{ getNumOfMiddlewaresFrom(null, null, true) }}</span>
                        <span class="hidden-label"> Mids. -wada IN</span>
                      </h5>
                    </h4> 
                    <span v-else>
                      <div v-if="isRealTime" class="d-flex align-items-center position-relative">
                        <h4 class="p-50 free-block">FREE</h4>
                        <span class="position-relative">
                          <span class="connection-point free"  @click="navToEmptyCategory(null, null, true)" :class="noMiddlewareIndicatorClass(null, null, true)"/>
                        </span>
                      </div>
                    </span>

                  </div>

                  
                  <div >

                  </div>
                        
                </div>
        </div>
                <div>
                  
              </div>

              </div>
            </flickity>
            <!-- ----------------------------------------------------------------- -->
          </b-col>
        </b-row>
      </div>
      <div ref="middleware-display" class="middleware-display draggable-middleware-display" :key="'draggable-middleware-display-'+previewVersion" v-if="!isRealTime">
        <middlewares-preview 
          v-if="viewingMidsCategory && !noneSelected"
          :category="viewingMidsCategory" 
          :middlewares="getMiddlewaresFrom(viewingMidsCategory.service, viewingMidsCategory.event, viewingMidsCategory.isOut)"
          @closed="noneSelected = true"
        />
      </div>
    </div>
    <div v-else>
      <h4 align="center" class="text-secondary my-2">Can't render Overview without any Permissions set.</h4>
    </div>

   </div>
</template>

<script>

import FiqonLogo from '@/views/pages/middleware/MiddlewareManagement/Components/ComponentSVGs/FiqonLogo.vue';

import ExecutionOrder from "@/views/pages/middleware/MiddlewareManagement/Components/FluxOverview/ExecutionOrderHelper.vue"
import Flickity from 'vue-flickity';
import MiddlewaresPreview from '@/views/pages/middleware/MiddlewareManagement/Components/FluxOverview/MiddlewaresPreview.vue'
import Middlewares from "@/custom/class/Enum/Middlewares.js"

import { 
  BCol,
  BRow,
  BIcon,
  BButton,
  BCollapse
 } from 'bootstrap-vue';
import anime from 'animejs';
import { toggleHeadingSmaller } from 'easymde';

export default {
  components: {
    FiqonLogo,
    BCol,
    BRow,
    BIcon,
    ExecutionOrder,
    BButton,
    BCollapse,
    Flickity,
    MiddlewaresPreview
  },
  props: {
    shownScript: {
      type: Object,
      default: undefined 
    },
    isRealTime:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      viewingMidsCategory: {
        service: null,
        event: null,
        isOut: false,
      },
      noneSelected: true,

      previewVersion: 0
    }
  },
  mounted () {
   this.moutDraggablePreview()
   this.initAnimations()
  },
  methods: {
    isLastOrFirst(idx , total) {
      return (idx == 0) || (idx == total-1)
    },
    getMiddlewaresFrom(service, event, isOut){
      //event.service.dir

      let s = "$NONE";
      let e = "$NONE";
      let dir;

      if (service){
        s = service
      }
      if (event){
        e = event
      }

      if (isOut){
        dir = "$OUT"
      } else {
        dir = "$IN"
      }
      
      let path = `${e}.${s}.${dir}`

      let mids = structuredClone(this.shownScript.middlewares)
      if (mids){
        return mids[path]
      } else {
        return undefined
      }
    },
    countScriptMiddlewares(middlewares){

      let midTypes = new Middlewares().items;
      let typesWithChildren = []

      midTypes.forEach(el => {
        if (el.hasChildren){
          typesWithChildren.push(el.id)
        }
      });

      let totalCount = 0

      function count(mids){
        mids.forEach(element => {
          if (!typesWithChildren.includes(element.type)){
            totalCount = totalCount + 1
          }

          if (element.middlewares.length > 0){
            count(element.middlewares)
          }
        });
      }
      count(middlewares)
      return totalCount
    },
    getNumOfMiddlewaresFrom(service, event, isOut){
      if (!this.isRealTime){
        let r = this.getMiddlewaresFrom(service, event, isOut)
        if (r && Array.isArray(r)){
          return this.countScriptMiddlewares(r)
        } else {
          return 0
        }
      } else {
        let mids = structuredClone(this.shownScript).middlewares
              
        let e = event || '$NONE'
        let s = service || '$NONE' 
        let dir = isOut ?  '$OUT' : '$IN'

        let r = mids[`${e}.${s}.${dir}`]
        return r
      }


    },
    showMiddlewares(service, event, isOut){
      if (this.isRealTime){
        this.viewingMidsCategory = {
          service: service,
          event: event,
          isOut: isOut
        }
        this.noneSelected = false
        this.$emit('putCategory', this.viewingMidsCategory)
      } else {
        this.viewingMidsCategory = {
          service: service,
          event: event,
          isOut: isOut
        }
        this.noneSelected = false
        this.moutDraggablePreview()
      }
    }, 
    navToEmptyCategory(service, event, isOut){
      if (!this.isRealTime){
        return
      }
      this.viewingMidsCategory = {
        service: service,
        event: event,
        isOut: isOut
      }
      this.noneSelected = false
      this.$emit('putCategory', this.viewingMidsCategory)
    } ,  
    numberIndecatorClass(s, e, isOut){
      if (this.noneSelected){
        return 
      }
      let current = structuredClone(this.viewingMidsCategory)
      if ( current.service == s && current.event == e && current.isOut == isOut ) {
        return "highlighted-indicator"
      }
    },    
    noMiddlewareIndicatorClass(s, e, isOut){
      if (this.noneSelected || !this.isRealTime){
        return 
      }
      let current = structuredClone(this.viewingMidsCategory)
      if ( current.service == s && current.event == e && current.isOut == isOut ) {
        return "highlighted-indicator"
      }
    },
    async moutDraggablePreview(){
      if (this.isRealTime){
        return
      }

      this.previewVersion= this.previewVersion + 1

      await this.$nextTick()

      dragElement(this.$refs['middleware-display']);

      function dragElement(elmnt) {
        if (!elmnt) return
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(elmnt.id + "header")) {
          // if present, the header is where you move the DIV from:
          document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        } else {
          // otherwise, move the DIV from anywhere inside the DIV:
          elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
          elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function closeDragElement() {
          // stop moving when mouse button is released:
          document.onmouseup = null;
          document.onmousemove = null;
        }
      }



    },
    initAnimations(){
      anime({
        targets: '.fiqon-logo',
        duration: 400,
        opacity: [0 , 1],
        scale: [1, 1.35],
        delay: 300,
        autoplay: true,
        easing: 'easeInQuad',
      })

      anime({
        targets: '.middleware-number-indicator',
        duration: 600,
        scale: [0, 1.2, 1],
        delay:50+ 100,
        autoplay: true,
        easing: 'easeInOutQuad'
      })
      
      anime({
        targets: '.connection-line',
        duration: 1300,
        scaleX: [0,1],
        delay: 50+500,
        autoplay: true,
        easing: 'easeOutQuad'
      })

      anime({
        targets: '.vertical-connector-line',
        duration: 500,
        scaleX: [0,1],
        scaleY: [0,1],
        delay: 50+500,
        autoplay: true,
        easing: 'easeOutQuad'
      })

      anime({
        targets: '.service-block',
        duration: 200,
        scaleX: [0 , 1],
        opacity: [0, 1],
        delay: 300,
        direction: 'normal',
        autoplay: true,
        easing: 'easeOutQuad',
      })

      anime({
        targets: '.no-middleware-indicator , .connection-point',
        duration: 500,
        opacity: [0, 1],
        scale: [0,1],
        delay: 50+500,
        direction: 'normal',
        autoplay: true,
        easing: 'easeOutQuad',
      })


    },
    setCategory(itm){
      this.noneSelected = false
      this.viewingMidsCategory = itm
    }
  },
};
</script>

<style lang="scss" scoped>

.middleware-display{
  position: absolute;
  bottom: 0;
  left: 30px;
}

.centering-container {
  display: flex !important;
  justify-content: space-between !important;
}

.aligned-to-middle {
 flex-grow: 1 !important;
 flex-basis: 0 !important;
}

.maxed-height-in-modal{
  min-height: 65vh;
}

@keyframes initialize-map {
  0%{
    opacity: 0.7;
    filter: brightness(0);
    transform: scale(0.99);
  }
   100% {
    filter: brightness(1);

    opacity: 1; 
    transform: scale(1);
  } 
    
}


.color-in{
  --dir-color: var(--favorite);
}
.color-out{
  --dir-color: var(--purple);
}

.visualization-map-container{
  --map-bg-color: rgba(13, 17, 29);

  .solid-bg{
    background-color: var(--map-bg-color);
  }

  .map-title{
    position: absolute;
    top: -30px;
    left: 5px;
    background-color: var(--map-bg-color);
    padding: 4px 10px;
    border-radius: 6px;
    border-top: 5px solid rgba(0, 0, 0, 0.2);
    border-left: 5px solid rgba(0, 0, 0, 0.2);
    border-right: 5px solid rgba(0, 0, 0, 0.2);
 
    
  }
 .middle-divider{
    position: absolute;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    height: 90%;
 }
  
  border-radius: 6px;
  position: relative ;
  border: 5px solid rgba(0, 0, 0, 0.2);
  animation: initialize-map  .5s ease-in !important;
  animation-iteration-count: 1 !important;

  background-color: var(--map-bg-color);
  width: max-content !important;
  min-width: 550px;
  min-height: 300px;
  
  margin: auto;
  padding: 0px 10px 10px 10px;

  //--shadow-filter: drop-shadow(8px 8px rgba(0, 0, 0, 0.5)); 

  --default-block-radius: 5px;
  .service-block{
    
    border: 1px solid var(--secondary);
    border-radius: var(--default-block-radius);
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: bolder;
    height: 36px;
    position: relative;
    margin:  21px 0 !important;

  
    .service-name{
      max-width: 250px;
      max-height: 1.4em;
      overflow: hidden;
      display: inline-block;
      word-break: break-all;
      white-space: nowrap; 
      text-overflow: ellipsis;
    }
  }
  .category-label{
    position: absolute;
    text-align: right;
    bottom: -14px;
    
    font-size: 9px;
    left: 0;
    width: 100%;
    color: var(--secondary);
    letter-spacing: 3px;
    &.left{
      direction: ltr;
      text-align: left;
    }
    &.right{
      direction: rtl;
      text-align: right;
    }
  }
  .category-label-static{
    font-size: 9px;
    font-weight: bold;
    color: var(--secondary);
    letter-spacing: 3px;
    margin: 2px 0;
  }
  .event-block{
    border: 1px solid var(--secondary);
    border-radius: var(--default-block-radius);
    max-width: 100%;
    font-size: 15px;
    word-break: break-all !important;
  }  
  
  .free-block{
    border-radius: var(--default-block-radius);
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
  }


  .in-out-label{
    //background-color: rgba(0, 0, 0, 1);
    width: 30%;
    min-width: 50px;
    text-align: center;
    //margin: 0 25px;
    border-bottom: 2px solid var(--dir-color);
    * { 
      font-size: 20px;
    }

  }


  .center-event-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h4{
      border-bottom: 1px solid var(--secondary);
    }

  }
  
  .connection-point{
    filter: var(--shadow-filter);
    border-radius: 100px;
    border: 6px solid var(--success);
    margin: 0 8px;
    z-index: 0;
    position: relative;
  }



  .connection-line{
    z-index: 0 ;
    position: relative;
    filter: var(--shadow-filter);
    width: 50px;
    margin-left: -8px;
    margin-right: -8px;
    position: relative !important;
    border: 1px solid var(--success);
    
    &.in-middle{
      margin-right: -19px;
      margin-left: -19px;
      width: 72px;
    }
  }

  .vertical-connector-line{
    z-index: 0;
    position: relative;
    padding-top: 38px;
    padding-bottom: 38px;
    height: 100% !important;
    width: 30px;
    
    position: absolute;

    &.left{
      margin-left: -10px;
      margin-right: 7px;
      div{
        z-index: 0;
        position: relative;
        border-radius: 0px var(--radius) var(--radius) 0 ;
        border-right: 2px solid var(--success);
      }
    }

    &.right{
      margin-right: -10px;
      margin-left: 7px;
      div{
        border-radius: var(--radius) 0 0 var(--radius) ;
        border-left: 2px solid var(--success);
      }
    }
  
    div{
      z-index: -1;
      position: relative;
      height: 100%;
      filter: var(--shadow-filter);
      --radius: 10px;

      border-top: 2px solid var(--success);
      border-bottom: 2px solid var(--success);
    }
  }


  .mr-negative{
    margin-right: -13px;
  }
  .ml-negative{
    margin-left: -13px;
  }
}

  .real-time .connection-point{
    cursor: pointer;
    transition: all 0.2s;
    z-index: 1;
    &:hover{
      outline: 2px solid var(--success);
    } 
    &.highlighted-indicator{
      animation: 1.5s highlight-no-middleware;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      
    }
    &.free{
      position: absolute;
      right: -6px !important;
      top: -28px;
      margin: 0;
    }
  }

@keyframes highlight-no-middleware{
  to, from{
    --blur: 5px;
    --spread: -1px;
  }
  0% , 100%{
    box-shadow: 
      0px 6px var(--blur) var(--spread) var(--outline-color),
      0px -6px var(--blur) var(--spread) var(--outline-color),
      6px 0px var(--blur) var(--spread) var(--outline-color),
      -6px 0px var(--blur) var(--spread) var(--outline-color)
    ;
    outline: 1px solid var(--outline-color);
  }
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
}

.middleware-number-indicator{
  min-width: 2em;
  text-align: center;
  user-select: none;
  cursor: pointer;
  border-radius: 13px;
  background-color: var(--light);
  color: var(--black) !important;
  color: var(--light);
  padding: 3px 5px;
  font-size: 12px;
  transition: padding 0.35s , border 0.05s , ;
  width: max-content !important;
  border: 2px solid var(--dark);
  
  border: 2px solid var(--dir-color);
  z-index: 1;
  position: relative;
  &:active{
    border: 3px solid rgba(255, 255, 255, 0.8);
  }

  .hidden-label{
    position: relative;
    display: none;
  }

  &:hover{
    z-index: 2;
    margin-left: calc(-45%);
    margin-right: calc(-45%);
    
    padding: 6px 8px;
    .hidden-label{
      display: unset;
    } 
    
  }
}

span.no-middleware-indicator{
  box-sizing: border-box !important;
  position: absolute;
  border-radius: 500px;
  padding: 5px;
  margin: 2px 0;
  background-color: var(--success);
  top: -7px;
  z-index: 1;
  
  &.left{
    left: 5;
  }
  &.right{
    right: 0;
  }
  &.negative-mt{
    top: -9px !important;
  }
  &.highlighted-indicator{
    animation: 1.5s highlight-no-middleware;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.real-time span.no-middleware-indicator{
  cursor: pointer;
  transition: all 0.2s !important;
  &:hover{
    transform: scale(1.4) !important;
    box-shadow: 0 0 10px black;
  }
}

.middleware-tooltip-indicator{
  position: absolute;
  top: -15px;
  &.left{
    left: -20px;
  }
  &.right{
    right: -20px;
  }
}
.w-37{
  width: fit-content ;
}

.helper-position{
  position: absolute !important;
  right: 9px;
  top: 10px;
}

.px-01{
  padding: 0 1px;
}

</style>


<style lang="scss">

.flickity-enabled.is-draggable .flickity-viewport{
  cursor: unset;
}

.flickity-viewport {
  transition: height 0.3s;
}


@keyframes selected-highlight{
  0%, 100%{
    box-shadow: 0 0 8px var(--outline-color);
  }
  50%{
    box-shadow: 0 0 15px 5px var(--outline-color);
  }
}


.highlighted-indicator{
  transition: all 0.4s;
  animation: 1.5s selected-highlight;
  animation-iteration-count: infinite;
  --outline-color: rgba(0, 119, 255, 1);
}

</style>

