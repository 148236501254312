<template>
  <app-timeline-item :variant="timelineVariant" class="p-0">
    <div class="d-flex align-items-center position-relative">
      <div class="position-absolute ml-25">
        <b-icon icon="moon" rotate="-135" scale="0.4" shift-v="0" shift-h="-2" :variant="timelineVariant" />
      </div>
      
      <b-button 
        class="d-flex align-items-center justify-content-start flex-wrap agent-container position-relative" 
        :class="[rearrangementOverlayOff() ? '' : 'draggable' , agentOpened ? 'agent-highlight' : '', agentMachedInSearch() ? 'matched-highlight' : '']"
        variant="none" 
        @click="editAgent()" 
      >

        <div class="d-flex align-items-center">
          <b-icon :icon="agentOpened ? 'pencil' : rearrangementOverlayOff() ? 'eye' :'arrow-down-up'" class="mr-50" scale="0.85" variant="light"/>
          <h5 class="m-0 rounded" :class="agentMachedInSearch(true) ? 'matched-highlight' : ''">{{ $t(agent.enum_agent_action.label) }}</h5>
        </div>

        <template v-for="reg in Object.keys(registers)">
          <div class="register-container" v-if="agent[reg].source">
            <b-badge :variant="'light-' + registers[reg].variant" class="mr-25 ml-2"> {{registers[reg].label}}</b-badge>
            <span class="small text-secondary">
              <b class="">
                {{ getSourceLabel(agent[reg].source) }}<span  v-if="agent[reg].value">:</span> 
              </b>
              {{ agent[reg].value }}
            </span>
          </div>
        </template>
      </b-button>

      <template v-if="!loadingDelete">
        <b-button variant="outline-danger" class="delete-agent-btn ml-75 round" @click="confirmDeleteAgent()" v-if="rearrangementOverlayOff()">
          <b-icon icon="x" scale="0.9"/>
        </b-button> 
        <b-icon  class="ml-50" scale="1" icon="arrow-down-up" variant="secondary" v-else/>
      </template>
      <b-spinner v-else small variant="danger" class="ml-75"/>
    </div>
    <agent-base
      :key="'agent-base-' + agentVersionKey"
      v-if="agent_item && middlewareID"
      :component="agent_item.component"
      :agentID="agent.id"
      :uuid="getID('sidebar-id')"
      :middlewareID="middlewareID"
      v-model="agent"
      :agentBlockID="agentBlockID"
      :isDebugMode="isDebugMode"
      @agentClosed="sidebarClosed()"
      @hidden="sidebarClosed()"
      @saved="onSaved"
    />
  </app-timeline-item>
</template>

<script>
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { 
  BAvatar, 
  BButton, 
  BTooltip,
  BBadge,
  BSpinner,
 } from "bootstrap-vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import { mapActions } from "vuex";
import { makeToast } from "@/layouts/components/Popups";
import { v4 as uuidv4 } from "uuid";
import EnumAgents from "@/custom/class/Enum/Agents";
import ViewDialog from './Agent/ViewDialog.vue'
import Agents from '@/layouts/components/Transmission/Middleware/Agent/List'
import Source from "@/custom/class/Agent/Source"
import EventBus from "@/custom/eventBus"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {mapGetters} from 'vuex'
import AgentBase from '@/layouts/components/Transmission/Middleware/Agent/List/AgentBase.vue'
import PortalVue from 'portal-vue'

export default {
  components: {
    ...Agents,
    AppTimelineItem,
    BAvatar,
    BButton,
    BTooltip,
    ViewDialog,
    VuePerfectScrollbar,
    BBadge,
    BSpinner,
    AgentBase,
    PortalVue
  },
  props: {
    middlewareID: {
      type: [ Number, String ],
      required: true
    },
    options: {
      type: Object,
      default: undefined,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    timelineVariant: {
      type: String,
      default: 'primary'
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1
    },
    isDebugMode:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      uuidMap: {},
      agent_item: undefined,
      registers: undefined,
      is_edit: false,
      loadingDelete: false,
      agent: new NormalizedAgent(this.options),
      deleteModal: undefined,
      agentVersionKey: 1,
      registers:{
        register_1 : {
          label: 'R1',
          variant: 'info'
        },
        register_2 : {
          label: 'R2',
          variant: 'info'
        },
        register_destiny : {
          label: 'RD',
          variant: 'success'
        },
      },
      agentOpened: false,
    };
  },
  mounted() {
    this.init();
    EventBus.$on("initiateAgentEdit", (payload) => {
      if(this.agent.id == payload.id) {
        this.editAgent();
      }
    });

    EventBus.$on("initiateAgentDelete", (payload) => {
      if(this.agent.id == payload.id) {
        this.confirmDeleteAgent();
      }
    });
  },
  beforeDestroy() {
    EventBus.$off("initiateAgentEdit");
    EventBus.$off("initiateAgentDelete");
  },
  computed: {
    ...mapGetters('internal',['fluxSearchMatched'])
  },
  methods: {
    ...mapGetters(["rearrangementOverlayOff", "getReorderingMiddlewares"]),
    ...mapActions(["deleteAgent"]),
    init() {
      const agents = new EnumAgents();
      this.agent_item = agents.items[this.agent.enum_agent_action.id];
    },
    async editAgent() {
      if (!this.rearrangementOverlayOff() || this.loadingDelete){
        return
      }
      this.agentVersionKey = this.agentVersionKey + 1;
      await this.$nextTick()
      this.is_edit = true
      this.$root.$emit('bv::toggle::collapse', this.getID('sidebar-id'))
      this.agentOpened = true;
      this.$store.dispatch("internal/setSidebarState" , this.agentOpened)
    },
    confirmDeleteAgent() {
      if (this.deleteModal){
        return
      }
      this.deleteModal = this.$bvModal
        .msgBoxConfirm(
          this.$t("agent.modal.delete.message", {
            "agent-type": this.$t(this.agent.enum_agent_action.label),
          }),
          {
            title: this.$t("agent.modal.delete.title"),
            size: "sm",
            okVariant: "danger",
            okTitle: this.$t("common.terms.yes"),
            cancelTitle: this.$t("common.terms.no"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
            autoFocusButton: "ok",
          }
        )
        .then((value) => {
          if (value) {
            this.loadingDelete = true
            this.deleteAgent({ id: this.agent.id, transmissionID: this.$route.params.transmissionID } )
              .then(() => {
                this.$emit("onDelete", { agent: this.agent });

                makeToast({
                  title: this.$t("agent.toast.delete.success.title"),
                  text: this.$t("agent.toast.delete.success.message"),
                  variant: "success",
                  icon: "CheckIcon",
                });
              })
              .catch(() => {
                makeToast({
                  title: this.$t("agent.toast.delete.error.title"),
                  text: this.$t("agent.toast.delete.error.message"),
                  variant: "danger",
                  icon: "XIcon",
                });
              });
          }
        })
        .finally(()=>{
          this.deleteModal = undefined
        })
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    onSaved(payload, closeSidebar=true) {
      
      if (closeSidebar){
        this.$root.$emit('bv::toggle::collapse', this.getID('sidebar-id'));
      }

      this.$set(this.agent.register_1, "value", payload.agent.register_1.value);
      this.$set(this.agent.register_2, "value", payload.agent.register_2.value);
      this.$set(this.agent.register_destiny, "value", payload.agent.register_destiny.value);

      if(this.is_edit) {
        this.$emit('onEdit', payload.response.data)
        this.is_edit = false
      }
    },
    getSourceLabel(id) {
      const source = new Source(id);
      return source.key;
    },
    sidebarClosed(){
      this.agentOpened = false;
      this.$store.dispatch("internal/setSidebarState" , this.agentOpened)
    },
    agentMachedInSearch(checkIfType = false){
        let matches = this.fluxSearchMatched
        let r = false;

        matches.forEach(m => {
          
          if (checkIfType){
            if (m.agent == this.agent.id && m.matchType == 'agentType'){
              r = true
            }
          } else if (m.agent == this.agent.id){
            r = true
          }

        })

        return r
      }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.agent-spot {
  min-width: 300px;
  width: fit-content;
}

.agent-spot-info{
  margin-bottom: 15px !important;
  max-height: 200px;
}


.matched-highlight{
  outline: 1px solid $selection-orange;
}


.agent-container{
  cursor: pointer;
  transition: .2s all linear ,outline .01s linear, .05s border linear;
  border: 1px solid transparent;
  padding: 8px 10px;
  border-radius: 10px;
  background-color: rgba(9, 12, 20,1);
  max-width: 35vw;
  width: fit-content;
  margin: 5px 0 5px 20px;
  text-align: left;
  &:hover , &:focus{
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  &:active{
    border: 1px solid var(--light);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
  }

  .register-container{
    max-height: 50px;
    overflow-y: auto;
  }

  &.draggable{
    cursor: grab;
  }
}

.agent-highlight{
  border: 1px dashed rgba(255, 255, 255, 0.4);
  filter: brightness(2.5) saturate(1.3);
}

.delete-agent-btn{
  padding: 2px;
}
</style>
