<template>
  <b-form-group
    :label="label"
    :label-for="getID('custom-input-scrollbar')"
  > 
    <template #label>
      <slot name="label">
        {{ label }}
      </slot>
      <span class="float-right">
        <slot name="label-right">
        </slot>
      </span>
    </template>
    <template #description>
      <slot name="changer" v-if="advanced">
        <span class="text-secondary" v-for="type in changerTypes" :key="getID(type)" :id="'text-id-'+getID(type)">
        <!-- <b-tooltip :target="'text-id-'+getID(type)" triggers="focus">d asd sadsad </b-tooltip> -->
          <span class="text-capitalize">
            {{ type }}: 
          </span>
          {{ getValue(type) }} 
          <small v-if="!noChanger" class="text-success cursor-pointer" @click="openChanger(type)">
            - CHANGE 
            <b-icon
              class="mt-25"
              icon="arrow-left-right"
              scale="1"
            />
          </small>
        </span>
      </slot>
    </template>


      <vue-perfect-scrollbar :id="getID('custom-input-scrollbar')" class="border rounded custom-container">
        <drop
          @drop.self="addVariable"
        >
          <div :class="customComputedClasses" class="custom-input shadow py-50 px-1 cursor-text" @click.self="focusInput()" >
            <span v-for="(item, key) in inputModels" :key="`${key}-${version}`">
              <template v-if="item.index != '' && item.type != 'constant'">
                <b-badge
                  v-if="dissecateItem(item.index)"
                  :variant="item.variant"
                  :title="item.title"
                  @click="badgeClicked(item)"
                  >
                  <span :class="item.domClass">
                    {{dissecateItem(item.index)[0]}}
                    <span class="verified-source-input">{{ dissecateItem(item.index)[1] }}</span>
                    <span class="unverified-source-input">{{ dissecateItem(item.index)[2] }}</span>
                    {{dissecateItem(item.index)[3]}}
                  </span>

                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="keyboardActions({ key: 'Click' }, key)"
                  />
                </b-badge>                
                <b-badge
                  v-else
                  :variant="item.variant"
                  :title="item.title"
                  @click="badgeClicked(item)"
                  >
                  <span :class="item.domClass">{{ item.index }}</span>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="keyboardActions({ key: 'Click' }, key)"
                  />
                </b-badge>
              </template>
              <span
                v-else
              >
                <drop
                  @drop="(data) => addVariablePositional(data, key)"
                > 
                  <b-form-input
                    placeholder=" +"
                    :ref="getID(key + version)"
                    class="variable-custom-input border rounded"
                    v-model="inputModels[key].index"
                    :id="'anchor-recommend-'+uuid"
                    :style="`
                      width: ${inputModels[key].index.length ? inputModels[key].index.length * 8.45 + 'px !important' : '25px !important'};
                      'z-index: 1 !important;'
                    `"                    
                    @keydown="(evt) => keyboardActions(evt, key)"
                    @blur.self="setElements(key)"
                    @blur="deactivateTooltip(); $emit('blur')"
                    @input="deactivateTooltip()"
                    @focus="inputFocused()"
                    :autofocus="autofocus"
                  />
                </drop>
              </span>
            </span>
          </div>
      </drop>
    </vue-perfect-scrollbar>

    <!-- INPUT RECOMMENDATION SYSTEM ======================= -->
    <template v-if="!disableSourceRecommend && (recommendationShowing &&  selectedSourceFields() && selectedSourceFields().length)">
      
      <b-tooltip 
        :target="'anchor-recommend-'+uuid" 
        triggers="hover" 
        placement="BOTTOM" 
        class="p-0" 
        :delay="{show:0, hide:1000}"
        variant="secondary" 
        :show="true" 
        :ref="'tooltip-recommend-'+uuid"
      >
        <div class="recommendation-item" @click="addToInput(field)" v-for="field in selectedSourceFields()" :key="'recommendation-item-'+field">
          {{field}}
        </div>
      </b-tooltip>
    </template>
    <!-- END OF INPUT RECOMMENDATION SYSTEM =================-->

    <b-modal
      :id="getID('custom-input-changer')"
      centered
      title="Source Changer"
      size="sm"
      no-close-on-backdrop
      @ok="submitChanger"
      :ref="'source-modal'+uuid"
      @hidden="focusInput()"
    >
      <v-select
        id="source-select"
        :options="selectChangerOptions"
        v-model="changerSelection"
        :clearable="false"
        @input="focusConfirmButton()"
      >
        <template #option="{ key, type, id, info }">
          
          <div :id="`source-select-item-${id}-${uuid}`">
            <span :class="key==changerSelection.key ? 'text-white font-weight-bold' : ''">{{ key }}</span>
            
            <template v-if="key!=changerSelection.key">
              <small class="float-right source-type-badge">
                <b-badge class="content" v-if="type=='middleware'" variant="light-danger">{{type}}</b-badge>
                <b-badge class="content" v-if="type=='request'" variant="light-info">{{type}}</b-badge>
                <b-badge class="content" v-if="type=='data'" variant="light-success">{{type}}</b-badge>
              </small>
            </template>
          </div>

          <b-tooltip
            triggers="hover" 
            :target="`source-select-item-${id}-${uuid}`" 
            placement="leftbottom" 
            :boundary-padding="350" 
            variant="dark-blue"
            :delay="{
              show: 200,
              hide: 0
            }"
            :key="'tooltip-'+id+'-'+uuid"
          
          > 
              <div class="max-width-tooltip font-weight-bold">
                {{$t(info)}}
              </div>

              <div class="text-secondary">
                <div class="d-flex justify-content-between mt-25 mb-0">
                  <hr class="w-25 mb-50">
                    <span v-if="getSourceFields(id).length" class="mt-25">Fields:</span>
                    <span v-else class="mt-25"> No Fields</span>
                  <hr class="w-25 mb-50">

                </div>

                <div class="w-100 small">
                  <span v-for="(item, index) in getSourceFields(id)" :key="`src-tooltip-field-${id}-${item}-${newUuid()}`">
                    <span>{{item}} </span>
                    <span v-if="!(index == getSourceFields(id).length-1)">, </span>
                  </span>
                </div>
              </div>

          </b-tooltip>
        </template>

          <template #selected-option="{ key }">
          <span>{{ key }}</span>
        </template>
        <template slot="no-options"> {{$t("terminal_tests.terms.no_options")}} </template>
      </v-select>

      <template #modal-footer>
        <div>
          <b-button class="mr-1" @click="$refs['source-modal'+uuid].hide()">Cancel</b-button>
          <b-button variant="success" @click="submitChanger()" :ref="'source-change-confirm-'+uuid">Confirm</b-button>
        </div>
      </template>
    </b-modal>



  </b-form-group>
</template>

<script>
import {
  BFormInput, 
  BFormGroup, 
  BForm, 
  BBadge, 
  BModal,
  BTooltip, 
  BDropdown, 
  BButton
} from 'bootstrap-vue'
import Drop from './Drop.vue'
import { v4 as uuidv4 } from 'uuid'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VSelect from 'vue-select'
import { makeToast } from '@/layouts/components/Popups'

import PossibleSourceValues from '@/custom/class/ProcessSourceValues.js'
//Classes
import Variable from '../class/Variable'
import Source from '@/custom/class/Agent/Source'
import Sources from '@/custom/class/Enum/Sources.js'

  export default {
    components: {
      BFormInput, 
      BFormGroup, 
      BForm,
      BBadge,
      Drop,
      VuePerfectScrollbar,
      VSelect,
      BModal,
      BTooltip,
      BDropdown,
      BButton
    },
    props: {
      value: {
        type: [ Object, String ],
        default: () => {}
      },
      possibleValues: {
        type: Object,
        required: true
      },
      horizontal: {
        type: Boolean,
        default: true,
      },
      writeDisabled: {
        type: Boolean,
        default: false
      },
      dropDisabled: {
        type: Boolean,
        default: false
      },
      changerOptions: {
        type: Object,
        default: () => { return {} }
      },
      label: {
        type: String,
        default: ''
      },
      advanced: {
        type: Boolean,
        default: true
      },
      noChanger: {
        type: Boolean,
        default: false
      },
      changerTypes: {
        type: Array,
        default: () => [ 'source' ]
      },
      disableSourceRecommend: {
        type: Boolean,
        default: false
      },
      autofocus:{
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        text: '',
        elements: [],
        items: {},
        inputModels: [],
        inputStateMap: [],
        uuidMap: {},

        version: 0,

        changerSelection: null,
        selectChangerOptions: undefined,
        uuid: uuidv4(),
        recommendationShowing: false,
        tooltipTimeout: undefined,
        doubleClick:{
          timer: undefined,
          hasClicked: false,
        }

      }
    },
    computed: {
      register: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      customComputedClasses() {
        return `${this.isHorizontal} ${this.isWriteDisabled}`;
      },
      isHorizontal() {
        return this.horizontal ? 'custom-input-horizontal': 'custom-input';
      },
      isWriteDisabled() {
        return this.writeDisabled ? 'custom-input-disabled' : '';
      },
      isDropDisabled() {
        return this.dropDisabled;
      },
      sourceOptions() {
        let items = new Sources().items.map(el => { return { ...el}})

        let r = []

        r = r.concat(items.filter(el => el.type == 'data'))
        r = r.concat(items.filter(el => el.type == 'request'))
        r = r.concat(items.filter(el => el.type == 'middleware'))

        return r;
      },
      possibleSourceInputs(){
        return new PossibleSourceValues().getAllSourcePaths()
      },    
    },
    mounted() {
      this.init();
      this.$emit('mounted')
    },
    methods: {
      init() {
        if (this.register == undefined) {
          this.defineRegisters()
        }
        this.setElements()
      },
      defineRegisters() {
        if (this.advanced) {
          this.register = {}
          return
        } 
        this.register = ''
      },
      addVariable(item) {
        if(this.inputModels.at(-1).type != 'variable') {
          this.inputModels.push(item)
        } else {
          this.inputModels.push(new Variable({}), item)
        }
        this.inputModels.push(new Variable({}))
        
        this.tagsToString()
      },
      addVariablePositional(item, key) { 
        let string = null

        if (key == 0) {
          
          string = new Variable({ type: 'constant', value: '', index: '', variant: 'light-secondary'})
          
          this.inputModels[key] = new Variable({ type: 'constant', value: '', index:  this.inputModels[key].index, variant: 'light-secondary', domClass:"strike-through"})
          this.inputModels.unshift(string, item)
        } else if (key == (this.inputModels.length - 1)) {
          
          string = new Variable({ type: 'constant', value: '', index: this.inputModels[key].index, variant: 'light-secondary'})
          
          this.inputModels[key] = string
          this.inputModels.push(item, new Variable({ type: 'constant', value: '', index: '', variant: 'light-secondary', domClass:"strike-through"}) )
        } else {

          string = new Variable({ type: 'constant', value: '', index: '', variant: 'light-secondary'})

          let arrFragment = this.inputModels.splice(key+1)
          arrFragment.unshift(item, string)
          this.inputModels = this.inputModels.concat(arrFragment)
        }

        this.tagsToString()
      },
      keyboardActions(evt, key) {
        if(evt.key == 'Backspace') {
          if (!key) return 
          switch(true) {
            case this.inputModels[key].index == "": {
              this.inputModels.splice(key-1, 2)
              this.setInputFocus(key-2)
              this.version++;
              break;
            }
          }
          this.tagsToString()
        }
        else if(evt.key == 'Delete') {
          if (this.inputModels.length - 1 == key) return
          switch(true) {
            case this.inputModels[key].index == "" : {
              this.inputModels.splice(key, 2)
              this.setInputFocus(key)
              this.version++;
              break;
            }
          }
          this.tagsToString()
        }
        else if(evt.key == 'Click') {
          switch(true) {
            case this.inputModels[key+1].index == "" : {
              this.inputModels.splice(key, 2)
              this.setInputFocus(key-1)
              break;
            }
            case this.inputModels[key+1].index != "" : {
              this.inputModels[key-1].index += this.inputModels[key+1].index
              this.inputModels.splice(key, 2)
              this.setInputFocus(key-1)
              break;
            }
          }
          this.tagsToString()

        }
        else if (evt.key == '{'){
          
          this.inputModels[key].index = `${this.inputModels[key].index}}`
          let pos = this.$refs[this.getID(key + this.version)][0].selectionStart
          this.$nextTick(()=>{
            this.$refs[this.getID(key + this.version)][0].setSelectionRange(pos, pos);
          })
          
        }
        else if(evt.key == 'ArrowLeft') {
          if (evt.ctrlKey || this.inputModels[key].index == '') {
            this.setInputFocus(key-2)
          }
        }
        else if(evt.key == 'ArrowRight') {
          if (evt.ctrlKey || this.inputModels[key].index == '') {
            this.setInputFocus(key+2)
          }
        }
      },
      badgeClicked(item){
        if (this.doubleClick.hasClicked){
          let idx = this.inputModels.findIndex(el => el == item)
          this.inputModels[idx].type = 'constant' 
        }
        clearTimeout(this.doubleClick.timer)
        this.doubleClick.hasClicked = true
        this.doubleClick.timer = setTimeout(() => {
          this.doubleClick.hasClicked = false
        }, 250);
      },
      setInputFocus(nextPos) {
        if(nextPos >= 0 && nextPos < this.inputModels.length) {
          this.$refs[this.getID(nextPos + this.version)][0].focus()
        }
      },
      setElements(key = null) {
        let text = ''
        //* Quando a função é chamada no evento de @blur do input é utilizada da "key" atual 
        if (key != null) {
          if (!this.inputModels[key]) return 
          text = this.inputModels[key].index
        }
        //* Quando prop value vem com String setada não há key no Array "inputModels"
        else {
          if (this.advanced) {
            text = this.register.value || ''
          } else {
            text = this.register || ''
          }
        }
        
        //* Regex que extrai todas string que contem "{{}}" ao redor e transforma em um Array
        let matches = [...text.matchAll(/{{(.{1,}?)}}/g)]; 
        
        //* Se houver variaveis na String de entrada do Regex
        if(matches.length) { 
          matches.forEach((el) => {
            let variable = null
            let string = null
            
            //*  Split na variável 'text' que contém a string de entrada, 
            //*  splitada no elemento de iteração do Array de resultado da Função de Regex.
            const val = text.split(el[0]);
            
            //* "out" recebe a primeira parte da string, podendo conter uma string inserida antes de um variável ou uma string vazia
            let out = val[0];

            //* Verifica se a variável detectada no Regex é um dos valores possíveis préviamente determinados
            // let trimmedMatch = el[0].replaceAll('{{' , '')
            // trimmedMatch = trimmedMatch.replaceAll('}}' , '')
            // let matchedToSource = false
            // this.possibleSourceInputs.forEach(src => {
            //   if (trimmedMatch.startsWith(src)){
            //     matchedToSource = true
            //   } 
            // })
            let matchedValue = undefined;
            let matchedVariant = undefined;
            
            Object.keys(this.possibleValues).forEach(outer => {
              if (this.possibleValues[outer][el[0]] != undefined) {
                matchedVariant = outer
                matchedValue = this.possibleValues[outer][el[0]]
              }  
            });
            let matchedToSource = this.checkSourceMatch(el[0])
      
            if (matchedToSource){
              //Caso o Match esteja no Enum de PossibleSourceInputs:
              variable = new Variable({ type: 'variable', value: matchedValue, index: el[0], variant: 'light-purple'})
            } else {
              if (matchedValue != undefined) {
                
                //* Caso exista é reconhecida e seu valor setado no input como badge
                variable = new Variable({ type: 'variable', value: matchedValue, index: el[0], variant: matchedVariant })
              } else if(el[0].startsWith('{{$.@')){

                variable = new Variable({ type: 'variable', value: matchedValue, index: el[0], variant: 'light-danger' })

              } else {
                
                //* Caso não exista é setada com "custom_variable" e seu index setado no input como badge
                variable = new Variable({ type: 'custom_variable', value: matchedValue, index: el[0], variant: 'light-secondary', domClass:"strike-through"})
              }
            }

            //* Cria uma instancia de "Variable" para constantes, "out" sendo a String que existe antes da variável
            string = new Variable({ type: 'constant', value: '', index: out, variant: 'light-secondary', domClass:"strike-through"})
            
            //? Funciona, mas acredito que o posicionamento de inserção possa ser generalizado
            if (key != null) {
              //* Se a 'key' atual da inserção de variáveis for a primeira posição do input 
              //* -> 1: recebe uma instancia de "Variable" com a string à direita da variável e coloca na primeira posição do array de elementos do input
              //* -> 2: insere a string e a variável no array de elementos do input, inserindo primeiro a variável, \n
              //*       depois inserindo "string" que era nossa primeira parte da string antes da variável detectada
              if (key == 0) {
                this.inputModels[key] = new Variable({ type: 'constant', value: '', index: val.at(-1), variant: 'light-secondary', domClass:"strike-through"}) //* 1
                this.inputModels.unshift(string, variable)      
              } 
              //* Se a 'key' atual da inserção de variáveis for a última posição do input 
              //* -> 3: insere "string" na posição atual de "key" contendo a string antes da variável "out"
              //* -> 4: insere no final do input de elementos a variável 
              //*       e uma instancia de 'Variable' contendo o elemento á direita da variável (restante da informação) 
              else if (key == (this.inputModels.length - 1)) {
                this.inputModels[key] = string                                                                                             //* 3
                this.inputModels.push(variable, new Variable({ type: 'constant', value: '', index: val.at(-1), variant: 'light-secondary', domClass:"strike-through"}) ) //* 4
              }
              //* Se a 'key' atual da inserção de variáveis for qualquer outra posição
              //* -> 5: "arrFragment" recebe uma parte do Array "inputModels" da posição de "key" até a posição final
              //* -> 6: a primeira posição de "arrFragment" recebe uma instância de "Variable" com o elemento a direita da variável ({{}}) que sofreu Split
              //* -> 7: insere a string e a variável no array de elementos do input, inserindo primeiro a variável, \n
              //*       depois inserindo "string" que era nossa primeira parte da string antes da variável detectada
              //* -> 8: o Array "inputModels" recebe a concatenação do que restou antes da posição 'key' que ainda está contido em "inputModels"
              //*       e o "arrFragment" que contem a as variáveis e strings inseridas no início, formando o array original atualizado.
              else {
                let arrFragment = this.inputModels.splice(key)                                                             //* 5
                arrFragment[0] = new Variable({ type: 'constant', value: '', index: val.at(-1), variant: 'light-secondary', domClass:"strike-through"})  //* 6
                arrFragment.unshift(string, variable)                                                                      //* 7
                this.inputModels = this.inputModels.concat(arrFragment)                                                    //* 8
              }

              //* Caso mais de uma variável seja detectada numa mesma posição inserida por um input a "key" é incrementada em 2
              //* pois sempre que uma nova varíavel é inserida no Array de elementos, são criadas duas novas posições,
              //* sendo necessário um reajuste de posicionamento para a correta inserção das próximas variáveis e strings
              key += 2

            } else {
            
              //* Caso esteja processando uma String vindo diretamente da Prop, os elementos são somente inseridos na ordem que foram lidos
              this.inputModels.push(string, variable)
            }

            //* Efetua o replace na concatenção do val[0] (string anterior à variável) e o el[0] (a variável) e os substitue por uma String vazia
            //* Isso para que na próxima leitura dos elementos seja possível trabalhar somente com os dados que serão necessários
            text = text.replace(val[0] + el[0], '');
          })
        }
        //* Caso a leitura da string seja a partir dos dados da Prop insere uma posição no final da definição das variáveis com o restante da string original ou uma String vazia
        if (key == null) this.inputModels.push(new Variable({ type: 'constant', value: '', index: text, variant: 'light-secondary', domClass:"strike-through"}));

        //* transforma o array de elementos na varíavel String final que será enviada para a API

        //remove sequential duplicate empty elements from inputModels

        let lastEl = undefined
        this.inputModels.forEach(el => {
          if (lastEl && (lastEl.index.length==0 &&  el.index.length==0)){
            let idx = this.inputModels.indexOf(el)
            if (idx > -1){
              this.inputModels.splice(idx, 1)
            }
          }
          lastEl = el
        });
        this.tagsToString()       
      },
      tagsToString() {
        let text = '';

        this.inputModels.forEach((item) => {
          text += item.index
        })
        
        if (this.advanced) {
          this.$set(this.register, 'value', text);
        } else {
          this.register = text
        }
        this.$emit('stringUpdated');
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      getSource(id) {
        return new Source(id);
      },
      // Advanced Functions (Changer)
      getValue(type) {
        switch (type) {
          case 'source': {
            return this.getSource(this.register.source).key
          } 
        }
      },
      openChanger(type) {
        let selected = undefined
        let r;
        switch (type) {
          case 'source': {
            selected = this.getSource(this.register.source)
            this.changerSelection = { ...selected}

            this.selectChangerOptions = this.sourceOptions
          }
        }

        this.$bvModal.show(this.getID('custom-input-changer'))
      },
      submitChanger() {
        this.$set(this.register, 'source', this.changerSelection.id)
        this.$emit('value-changed', 'source')
        this.$refs['source-modal'+this.uuid].hide()
      },
      checkSourceMatch(txt){
        let trimmedMatch = txt.replaceAll('{{$.' , '')
        trimmedMatch = trimmedMatch.replaceAll('}}' , '')
        let matchedToSource = false
        this.possibleSourceInputs.forEach(src => {
          if (trimmedMatch.startsWith(src)){
            if (!matchedToSource || matchedToSource[1].length < src){
              let temp = trimmedMatch
              matchedToSource = []
              matchedToSource[0] = '{{$.'
              matchedToSource[1] = src
              matchedToSource[2] = temp.replaceAll(src,'')
              matchedToSource[3] = '}}'
            }
          } 
        })

        return matchedToSource
      },
      dissecateItem(txt){
        let trimmedMatch = txt.replaceAll('{{$.' , '')
        trimmedMatch = trimmedMatch.replaceAll('}}' , '')
        let matchedToSource = false
        this.possibleSourceInputs.forEach(src => {
          if (trimmedMatch.startsWith(src)){
            if (!matchedToSource || matchedToSource[1].length < src.length){
              let temp = trimmedMatch
              matchedToSource = []
              matchedToSource[1] = src
              matchedToSource[2] = temp.replaceAll(src,'')
            }
          } 
        })

        if (matchedToSource){
          matchedToSource[0] = '{{'
          matchedToSource[1] = '$.'+matchedToSource[1]
          matchedToSource[2] = matchedToSource[2]
          matchedToSource[3] = '}}'
        }
        return matchedToSource
      },
      deactivateTooltip(){
        clearTimeout(this.tooltipTimeout)
        this.tooltipTimeout = setTimeout(() => {
          this.recommendationShowing = false
        }, 100);
      },
      inputFocused(){
        clearTimeout(this.tooltipTimeout)
        if (!this.register.value || !this.register.value.length){
          this.recommendationShowing = true
        }
      },
      addToInput(txt){
        clearTimeout(this.tooltipTimeout)
        this.$set(this.register, 'value', this.register.value + txt)
        this.inputModels[0].index = txt
        this.recommendationShowing = false
        this.focusInput()
      },
      focusConfirmButton(){
        this.$nextTick(()=>{
          this.$refs['source-change-confirm-'+this.uuid].focus()
        })
      },
      getSourceFields(id){
        let sources = new Sources().items
        let r;
        r = sources.find(el => el.id == id)
        if (!r){
          return
        }
        return r.fields
      },
      newUuid(){
        return uuidv4()
      },
      selectedSourceFields(){
        let selected = this.register.source
        let sources = new Sources().items
        let r;
        r = sources.find(el => el.id == selected)
        if (!r){
          return
        }
        return r.fields
      },
      focusInput(focusFirst = false){
        
        let idx = 0
        if (focusFirst){
          idx = this.inputModels.length - 1
        }
        this.$refs[this.getID((idx) + this.version)][0].focus()

        // this.$refs[this.getID((this.inputModels.length - 1) + this.version)][0].focus()

        // this.$refs[this.getID(0 + this.version)][0].focus()
        
        // this.$nextTick(()=>{
        // })
      }
    }
  }
</script>

<style lang="scss">
.custom-container{
  .ps__thumb-x{
    max-height: 5px !important;
    opacity: 0.4;
  }
  .ps__rail-x{
    max-height: 6px !important;
  }
}
</style>

<style lang="scss" scoped>
.recommendation-item{
  cursor: pointer;
  background-color: rgba(21, 25, 37, 0.5);
  padding: 1px 5px;
  margin: 0 -5px 3px -5px;
  border-radius: 2px;
  &:hover{
    background-color: rgba(21, 25, 37, 0.75);
  }
  &:active{
    background-color: rgba(39, 39, 49, 0.75);
  }
}

.max-width-tooltip{
  max-width: 200px;
}

.verified-source-input{
}

.unverified-source-input{
  opacity: 0.7;
  filter: brightness(1.3);
  font-weight: 400;
}
.strike-through{
  text-decoration: wavy underline var(--danger);
}

.variable-custom-input {
  font-family: 'Courier New';
  width: auto !important;
  display: inline-block;
  border: none !important;
  padding: 0 !important;
  height: 30px  !important;
  box-shadow: none !important;
  min-width: 8.4px !important;
  user-select: contain !important;
  position: relative;
  z-index: 0;

  &:focus-within {
    &::placeholder {
      color: transparent !important;
    }
  }
}

.custom-container {
  background-color: #283046;
  &:hover {
    .custom-input-horizontal {
      //padding-bottom: 35px !important;
    }
  }
}

.custom-input {
  max-height: 100px !important;
  
}

.custom-input-disabled {
  pointer-events: none !important;
}

.custom-input-horizontal {
  overflow: hidden !important;
  width: max-content !important;
  min-width: 100%;
  max-height: 35px !important;
  transition: all 0.05s;
  background-color: #283046;
}

.source-type-badge{
  opacity: 0.7;
  //margin-right: -10px;
  .content{
    width: 100%;
    padding: 5px;
    margin: 0;
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  }
}

</style>