<template>
  <div class="d-inline">
    <b-card
      class="middleware-card pb-0 mb-0 mt-0 d-inline-block zoom-it animation-insert-middleware"  
      :class="[
         hasMetaData ? ` light-purple ` : ' default ' , isThisSelected ? ' middleware-selected ' : ' ', rearrangementOverlayOff ? '' : 'middleware-card-overlay-on ' , isOpened && hasActions && hasAgents ? 'opened-highlight' : '' , hasActions && hasAgents ? 'is-editable' : '', middlewareMachedInSearch() ? 'matched-highlight' : ''] "
      body-class="px-75 py-0"
      @click="middlewareClicked()"
      ref="middleware-card"
    >
      <span class="editing-flag " :class="isOpened && hasActions && hasAgents ? '' : 'opacity-0'">
        <b-icon icon="pencil" shift-v="1" class="mr-25"/>
        Editing . . .
      </span>

      <h2 class="d-flex justify-content-between align-items-center my-25">
        <div class="d-flex align-items-center">
          <b-avatar
            :src="`${metaData.avatar || 'https://robohash.org/' + metaData.id}`"
            class="mr-50"
            :variant="middleware.enum_type.variant"
            rounded
            size="40px"
          >
            <b-icon
              v-if="!hasMetaData && icon"
              :icon="icon"
              scale="1.7"
            />
          </b-avatar>
          <p class="d-inline-block adjust-top">
            <span :id="getID('middleware-name')"  v-if="!isEditing">
              <span  class="middleware-name text-truncate rounded" :class="middlewareMachedInSearch('name') ? 'matched-highlight': ''">{{middleware.name}}</span>
              <b-button class="px-25 pt-0 pb-25" variant="none" @click="toggleEdit()" v-on:click.stop>
                <b-icon icon="pencil-square" class="m-0" scale="0.8" shift-v="0" variant="secondary" />
              </b-button> 
            </span>
            <b-form-input
              v-else
              autofocus
              class="m-0 py-0 middsleware-name"
              :id="getID('edit-name')"
              :placeholder="$t('middleware.input.name.placeholder')"
              v-model.trim="name"
              v-on:click.stop
              @blur="editMiddlewareName"
              @keydown.enter="editMiddlewareName"
              :readonly="!rearrangementOverlayOff"
              :disabled="!rearrangementOverlayOff"
            />
            <span v-if="!hasMetaData" class="text-muted small d-block middleware-description" :class="middlewareMachedInSearch('type') ? 'matched-highlight': ''">
              {{
                $t(middleware.enum_type.label)
              }}
            </span>
            <span v-else class="text-muted small d-block middleware-description limited-width">
              {{metaData.url || "No URL defined"}}
            </span>
          </p>
        </div>
        <div>
          <div class="mt-25 d-flex align-items-center pr-1" v-if="hasActions">
            <b-icon
              :id="getID('is-fatal')"
              :icon="loadingFatal ? 'arrow-repeat' : 'exclamation-triangle'"
              class="cursor-pointer my-1 mr-2 fatal-toggle-btn"
              :class="middleware.is_fatal ? 'text-warning' : 'text-secondary'"
              scale="0.8"
              :animation="loadingFatal ? 'spin' : ''"
              @click="userCantEdit ? '' : toggleIsFatal()"
              v-on:click.stop
            />
            <div class="absolute-corner-right">
              <b-button
                class="delete-middleware-btn"
                variant="none"
                @click="$emit('deleteMiddleware')"
                v-on:click.stop
              >
                <b-icon icon="x" scale="1" class=""/>
              </b-button>

              <b-button 
                :id="getID('toggle-active')"
                class="disable-middleware-btn" :disabled="middleware.is_active == null" variant="none" @click.stop="$emit('toggleMiddlewareActive', !middleware.is_active)">
                
                <b-iconstack class="p-0 m-0" scale="0.6" v-if="middleware.is_active != null">
                  
                  <b-icon icon="circle-fill" stacked :variant=" middleware.is_active ? 'black' : 'light'" scale="1"/> 
                  <b-icon icon="circle-fill" stacked :variant="middleware.is_active ? 'secondary' : 'danger'" scale="0.75"/> 
                    
                </b-iconstack> 
                <b-spinner v-else class="m-0 p-0" variant="dark" small/>

              </b-button>
              <b-tooltip :key="middleware.is_active + 'active-inactive'" placement="right" :target="getID('toggle-active')" triggers="hover" :delay="{ 'show': 400, 'hide': 50 }">
                <span v-if="middleware.is_active == true">Turn middleware <b>Inactive</b>?</span>
                <span v-else>Turn middleware <b>Active</b>?</span>
              </b-tooltip>
            </div>
          </div>
        </div>
      </h2>
    </b-card>  

    <!-- {{ middleware.id }} -->
  
  </div>
</template>

<script>
import { 
  BCard, 
  BAvatar, 
  BIcon, 
  BFormInput, 
  BTooltip, 
  BBadge,
  BButton,
  BIconstack,
  BSpinner,
 } from 'bootstrap-vue';
import { makeToast } from '@/layouts/components/Popups';
import { v4 as uuidv4 } from 'uuid'
import Method from '@/custom/class/Integrator/Method'
import { mapGetters } from 'vuex';

  export default {
    components: {
      BCard, 
      BAvatar, 
      BIcon,
      BFormInput, 
      BTooltip,
      BBadge,
      BButton,
      BIconstack,
      BSpinner,
    },
    props: {
      value: {
        type: Object,
        required: true
      },
      hasActions: {
        type: Boolean,
        default: false
      },
      hasAgents: {
        type: Boolean,
        default: true
      },
      icon: {
        type: String,
        default: 'QuestionIcon'
      },
      metaData: {
        type: Object,
        required: true
      },
      isOpened:{ 
        type: Boolean,
        default: false,
      }
    },
    data() {
      return { 
        id: null,
        name: '',
        previousName: '',
        uuidMap: {},
        versionKey: 0,

        isEditing: false,
        loadingFatal: false,
        middlewareActionsShown: false,
        timelineShown: false,
      }
    },
    computed: {
      middleware: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      ...mapGetters(["rearrangementOverlayOff" , "getSelectedMiddlewares" , "getReorderingMiddlewares"]),
      ...mapGetters('internal',['fluxSearchMatched','fluxSearchIndex']),
      transmissionID() {
        return this.$route.params.transmissionID
      },
      hasMetaData() {
        return Object.keys(this.metaData).length
      },
      userCantEdit(){
        return !(this.$can('create') && this.rearrangementOverlayOff )
      },
      isThisSelected(){
        return this.getSelectedMiddlewares.selectedIDs.includes(this.middleware.id)
      },
      isThisReordering(){
        return this.getReorderingMiddlewares.selectedIDs.includes(this.middleware.id)
      }
    },
    mounted() {
      this.id = this.middleware.id
      this.name = this.middleware.name
    },  
    methods: {
      middlewareClicked(){
        
        if (!this.rearrangementOverlayOff){
          this.$emit('selectMiddleware', undefined)
          return
        }
        
        if (this.rearrangementOverlayOff && this.hasActions && this.hasAgents){
          this.toggleMiddlewareSidebar();
        }

      },
      toggleEdit() {
        if (!this.rearrangementOverlayOff){
          return
        }

        if (!this.isEditing) {
          this.previousName = this.name;
        }
        this.isEditing = !this.isEditing;
      },
      editMiddlewareName() {
        if (this.name.length > 2 && this.previousName != this.name) {
          if (!this.changingName) {
            this.changingName = true;

            this.$store.dispatch('patchMiddlewareName', { id: this.middleware.id, name: this.name, transmissionID: this.transmissionID})
              .then(() => {
                this.changingName = false;
                makeToast({
                  title: this.$t("middleware.toast.patch_name.success.title"),
                  text: this.$t("middleware.toast.patch_name.success.message"),
                  variant: "success",
                  icon: "CheckIcon",
                });
              })
              .catch((error) => {
                console.error(error)
                makeToast({
                  title: this.$t("middleware.toast.patch_name.error.title"),
                  text: this.$t("middleware.toast.patch_name.error.message"),
                  variant: "danger",
                  icon: "XIcon",
                });
              });
          }

          this.middleware.name = this.name;
        } else {
          this.name = this.middleware.name;
        }
        this.isEditing = false;
      },
      toggleIsFatal() {
        this.$bvModal
          .msgBoxConfirm(
            this.$t("middleware.modal.fatal_toggle.message",  { name: this.middleware.name, state: this.middleware.is_fatal ? this.$t('common.terms.not') : ''} ),
            {
              title: this.$t("middleware.modal.fatal_toggle.title"),
              size: "md",
              okVariant: 'warning',
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
              autoFocusButton: 'ok',
            }
          )
          .then((value) => {
            if (value) {
              this.loadingFatal = true;
              this.$store
                .dispatch("patchMiddleware", { new_value: !this.middleware.is_fatal, id: this.middleware.id, field: 'is_fatal', transmissionID: this.transmissionID })
                .then((response) => {
                  this.middleware.is_fatal = response.is_fatal 
                  this.loadingFatal = false;
                  makeToast({
                    title: this.$t(
                      "middleware.toast.fatal_toggle.success.title"
                    ),
                    text: this.$t(
                      "middleware.toast.fatal_toggle.success.message"
                    ),
                    variant: "success",
                    icon: "CheckIcon",
                  });
                })
                .catch((error) => {
                  makeToast({
                    title: this.$t(
                      "middleware.toast.fatal_toggle.error.title"
                    ),
                    text: this.$t(
                      "middleware.toast.fatal_toggle.error.message"
                    ),
                    variant: "danger",
                    icon: "XIcon",
                  });
                });
            }
          });
      },
      toggleMiddlewareSidebar() {
        this.$emit('toggleSidebar')
      },
      toggleMiddlewareActions() {
        if (!this.rearrangementOverlayOff){
          return
        }

        this.middlewareActionsShown = !this.middlewareActionsShown;
        this.$emit('toggleActions', this.middlewareActionsShown);
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      shorttenedString(str, size) {
        if(str.length > size) {
          str = str.substring(0, size) + '. . .';
        }
        return str
      },
      integratorMethod(field = null) {
        let method = new Method(this.metaData.enum_metodo.id || this.metaData.enum_metodo)
        return field ? method[field] : method
      },
      toggleTimeline(){
        this.$emit('toggleAgents', !this.timelineShown)
        this.timelineShown = !this.timelineShown
      },
      middlewareMachedInSearch(checkBy=undefined){
        let matches = this.fluxSearchMatched
        let r = false;
        let focus = false;
        
        let idx = 1
        matches.forEach(m => {
          if (checkBy && checkBy == 'name'){
            if (m.middleware == this.middleware.id && m.matchType == "middlewareName"){
              r = true
              if (idx == this.fluxSearchIndex){
                focus = true
              }
            }
          } else if (checkBy && checkBy == 'type'){
            if (m.middleware == this.middleware.id && m.matchType == "middlewareType"){
              r = true
              if (idx == this.fluxSearchIndex){
                focus = true
              }
            }  
          }
          else if (m.middleware == this.middleware.id){
            r = true
            if (idx == this.fluxSearchIndex){
              focus = true
            }
          }          
            idx = idx + 1
        })
        
        if (r && this.$refs['middleware-card'] && focus){
          this.$nextTick(()=>{
            this.$refs['middleware-card'].scrollIntoView({behavior: "smooth",block:"center", inline:"center"})
          })
        }


        return r
      }
    },
  }
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.limited-width{
  max-width: 280px;
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}

.middleware-card {
  max-width: 450px;
  min-width: 450px;
  width: 100%;
  box-shadow: 0 0 1px transparent;
  &.is-editable{
    cursor: pointer;
    &:hover{
      box-shadow: -3px 3px 0 1px rgba(0, 0, 0, 0.25); 
      background-color: #2b334b;
      
      //.disable-middleware-btn{
      //  opacity: 1;
      //}
    }
    &:active , &:focus{
      background-color: #323b57;
    }
  }

  box-sizing: content-box !important;
  .adjust-top {
    position: relative;
    top: 10px;
    font-size: 19px;
    
    --transition-time: 0.25s;
    transition: border-color 0.4s linear ,outline 0.4s linear , filter var(--transition-time)  linear,  box-shadow var(--transition-time)  linear, transform 0.05s linear, opacity 0.2s !important;
    border-left: 0px solid transparent;
    .middleware-name {
      display: inline-block !important;
      width: fit-content; 
      max-width: 290px;
      font-size: 16px;
      margin-bottom: -4px;
    }
  }

  &.light-warning {
    border-left: 7px solid var(--warning);
  }
  &.light-info {
    border-left: 7px solid var(--info);
  }
  &.light-purple {
    border-left: 7px solid rgba(114, 103, 240, 0.5);
  }
  &.light-secondary {
    border-left: 7px solid var(--secondary);
  }
  &.light-danger {
    border-left: 7px solid var(--danger);
  }
  &.default {
    border-left: 7px solid var(--dark-blue);
  }

  &.opened-highlight{
    filter: brightness(1.3)  drop-shadow(0 0 2px  rgba(255, 255, 255, 0.1)) contrast(1.15);
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.5);
    border-color: rgba(255, 255, 255, 0.3);
    outline: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.matched-highlight{
  outline: 1px solid $selection-orange;
}


@keyframes animation-insert-middleware {
  0%   {
    transform: scaleY(0.1);
    //opacity: 0;
    
  }
  100% {
    //opacity: 1;
    transform: scaleY(1);
  }
}

.animation-insert-middleware{
  animation-name: animation-insert-middleware;
  animation-duration: 0.45s;
  animation-iteration-count: 1;
}

.middleware-card-overlay-on{
  cursor: pointer;
  *{ //disables other functionalitys inside middleware card, like Toggle Fatal or Edit Name
    pointer-events: none;
  }
  &:hover{
    outline: 1px solid rgba(255, 255, 255, 0.5);
  }
}

@keyframes animation-floaty-middleware {
  0%   {
    transform: translateY(0px);
  }
  50%{
    transform: translateY(6px);
  }
  100% {    
    transform: translateY(0px);
  }
}

.middleware-selected {
  position: relative;
  outline: 2px dashed rgba(255, 255, 255, 0.2) !important;
  filter: brightness(1.4);
  margin-left: 15px;
  animation-name: animation-floaty-middleware;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  box-shadow: 10px 10px 5px 3px rgba(0, 0, 0, 0.4);
}

.editing-flag{
  position: absolute;
  top: -19px;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.2s linear;
  letter-spacing: 1px;
  white-space: nowrap;
}

.opacity-0{
  opacity: 0;
}

.absolute-corner-right{
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.disable-middleware-btn{
  //background-color: black;
  padding: 2px 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  //border-right: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 0 0 5px 0px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  z-index: 2;
  position: relative;
  
  &:hover{
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(255, 255, 255, 0.1);
  }
  transition: all 0.2s;
  //opacity: 0;
}


.delete-middleware-btn{
  margin-top: 0;
  padding: 1px 2px;
  border-radius: 0 5px 0px 0px;
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  *{transition: all 0.1s;}
  &:hover{
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.fatal-toggle-btn{
  &:hover , &:focus{
    filter: brightness(1.3);
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 0 3px rgba(0, 0, 0, 0.1);
  }
}


.middleware-description{
  margin-top: -4px;
  margin-bottom: 3px;
  border-radius: 3px;
}
</style>
