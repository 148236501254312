export default class MiddlewareCategories {
  constructor() {
    this.items = {
      requester: {
        label: "enum.middleware.category.requester",
        icon: "cursor"
      },
      custom: {
        label: "enum.middleware.category.custom",
        icon: "tools"
      },
      connectivity: {
        label: "enum.middleware.category.connectivity",
        icon: "globe2"
      },
      internal: {
        label: "enum.middleware.category.internal",
        icon: "house"
      },
      loop: {
        label: "enum.middleware.category.loop",
        icon: "arrow-repeat"
      },
      logic: {
        label: "enum.middleware.category.logic",
        icon: "diagram2"
      },
      ftp: {
        label: "enum.middleware.category.ftp",
        icon: "file-earmark"
      },
      mysql: {
        label: "enum.middleware.category.mysql",
        icon: "server"
      },
      mongo: {
        label: "enum.middleware.category.mongo",
        icon: "server"
      },
      postgressql: {
        label: "enum.middleware.category.postgressql",
        icon: "server"
      },
      editor: {
        label: "enum.middleware.category.editor",
        icon: "file-code"
      },
    };
  }
}
