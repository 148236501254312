<template>
  <div class="position-relative" v-if="isThisCategory() && false"> <!-- == REMOVED == -->
    <b-button class="main-revert-button" variant="none" @click="open()">
      <b-icon icon="arrow-repeat" rotate="-45" class="mr-25" shift-v="1"/>
      <span>Rollback</span>
    </b-button>

    <b-modal
      ref="rollback-modal"
      centered
      hide-footer
      body-class="rounded"
    >
    <template #modal-title>
      <div>
        <h4 class="m-0">
          <b-icon icon="arrow-repeat" rotate="-45" class="mr-25"/> Middleware Rollback
        </h4>
      </div>
    </template>

    <div>
      <h5 class="mb-25">Previous Autosave of this Flux:</h5>

      <div align="right">
        <span class="small text-secondary mx-50" v-if="storedDate">
          <span class="mr-25">Saved on:</span> <b>{{formatDate()}}</b>
        </span>
      </div>

        <div class="currently-saved-flux">
          {{storedFlux64}}
        </div>

      <div>
        <b-button variant="none" class="py-25 px-50 w-100 bg-dark-blue square import-rollback-btn" @click="importRollback()">
          Import this to Clipboard
          <b-icon icon="box-arrow-in-down-left" class="ml-25" scale="0.9"/>
        </b-button>
      </div>
    </div>

      <div> 
        <h5 class="small mt-25">
          <b-icon icon="square-fill" variant="success" scale="0.5"/>
          You can also 
          <b-link :to="{name: 'scripts', params:{transmissionID: $route.params.transmissionID}}">Rollback to a previously compiled <b>Script</b></b-link>
        </h5>
      </div>

    </b-modal>
    
  </div>
</template>

<script>
import { 
  BButton,
  BModal,
  BFormInput,
  BSpinner,
  BSkeleton,
  BTooltip,
  BLink,
  BBadge,
} 
from "bootstrap-vue";

import * as StringCompressor from '@/custom/class/FunctionClasses/StringCompressor.js';
import moment from "moment";
import { commonToast, successToast } from "@/custom/class/FunctionClasses/CommonToasts";
import { mapGetters } from 'vuex';

export default {
  components: {
    BButton,
    BModal,
    BFormInput,
    BSpinner,
    BSkeleton,
    BTooltip,
    BLink,
    BBadge,
  },
  data() {
    return {
      storedFlux64: undefined,
      storedDate: undefined,
      storedCategory: undefined,

      changeHasOccured: false,
    }
  },
  props: {
    category: {
      type: Object,
      default: undefined 
    },
    middlewares: {
      type: [Object, Array],
      default: undefined 
    },
  },
  computed: {
    ...mapGetters(["getRollbackUpdater"]),
  },
  watch: {
    getRollbackUpdater(newValue, oldValue) {
      setTimeout(() => {
        this.storeCurrentFlux()
      }, 200);
    }
  },
  mounted () {
    this.getCurrentStoredFlux();
  },
  destroyed () {
    window.onbeforeunload = () => {}
  },
  methods: {
    open(){
      this.storedFlux64 = undefined
      this.getCurrentStoredFlux()
      this.$refs['rollback-modal'].show()
    },
    storeCurrentFlux(){
      if (!this.changeHasOccured){
        window.onbeforeunload = (e)=> {
          if (true) {
            e.preventDefault()
          }
        }
        this.changeHasOccured = true;
      }
      
      let toStore = {
        data: StringCompressor.compressToBase64(JSON.stringify(structuredClone(this.middlewares))),
        date: new Date(),
        category: structuredClone(this.category)
      }

      toStore = JSON.stringify(toStore);
      localStorage.setItem('localMiddlewareBackup' , toStore)
      this.getCurrentStoredFlux();
    },
    getCurrentStoredFlux(){
      let s = localStorage.getItem('localMiddlewareBackup')
      if (s){
        s = JSON.parse(s)
        this.storedFlux64 = s.data
        this.storedDate = s.date
        this.storedCategory = s.category
      }
    },
    formatDate(){
      let d = this.storedDate
      if (d){
        d = new Date(d)
        return moment(d).format('MMM D - h:mm a')
      }      
    },
    isThisCategory(){
      if (!this.category || !this.storedCategory){
        return false
      }

      let service = this.category.service_id == this.storedCategory.service_id 
      let event = this.category.event_id == this.storedCategory.event_id 
      let direction = this.category.direction.id == this.storedCategory.direction.id
      
      if (service && event && direction){
        return true
      } else {
        return false
      }
    },
    importRollback(){
      this.$refs['rollback-modal'].hide()
      this.$emit('forceImport', this.storedFlux64)
    }
  },
};
</script>

<style lang="scss" scoped>

.main-revert-button{
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
}

.currently-saved-flux{
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: rgb(15, 20, 34);
  padding: 10px;
  border-radius: 5px 5px 0 0;
  text-align: justify;
  word-break: break-all;
  max-height: 350px;
  overflow: auto;
  color: rgba(255, 255, 255, 0.5)
}

.import-rollback-btn{
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-top: none;
  background-color: none;
  color: unset;
  border-radius: 0 0 5px 5px;
}

</style>
