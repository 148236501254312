<template>
  <div>
    <div class="middleware-container" v-if="middleware && middlewareType">
      <div class="middleware-wrapper position-relative">

        <middleware-card
          :key="getID(`middleawre-card-${middleware.id}`)"
          :metaData="middlewareMetadata"
          v-model="middleware"
          :hasActions="!middlewareType.is_end_block"
          :hasAgents="!no_agents"
          :icon="middlewareIcon"
          @toggleActions="toggleMiddlewareActions"
          @toggleSidebar="toggleMiddlewareSidebar"
          @selectMiddleware="selectMiddleware"
          :isOpened="sidebarOpened"
          @toggleAgents="toggleTimeline"
          @deleteMiddleware="deleteMiddleware"
          @toggleMiddlewareActive="(e) => $emit('toggleMiddlewareActive', e)"
        />

        <middleware-actions
          v-if="!middlewareType.is_end_block"
          class="zoom-it middleware-actions-container"
          :hasAgents="!no_agents"
          :timelineShown="timelineShown"
          @selectAgent="selectAgent"
          @deleteMiddleware="deleteMiddleware"
          @toggleAgents="toggleTimeline"
        />
        

        <div
          v-if="middleware.middlewares && middleware.middlewares.length" 
          class="d-flex justify-content-end zoom-it"
        >
          <div class="mx-50 cursor-pointer show-middleware-tab text-center"
            @click="toggleMiddlewares"
          >
            {{ middlewaresShown ? "hide middlewares" : "show middlewares" }}
          </div>
        </div>
        <div v-else class="mb-2 zoom-it"/>

      </div>

      

      <b-collapse
        class="middleware-timeline zoom-it "
        v-model="timelineShown"
      >
        <app-timeline>
            <div
              v-for="(agentBlock, index) in tryCatchAgentBlock" :key="`agent-block-${getID($t(agentBlock.label))}`"
            >
              <div class="d-flex justify-content-between align-items-center agent-spot-header-size agent-block-divider standard-box-shadow position-relative" v-if="!no_agents">
                <div class="d-flex align-items-center">
                  <h3 :variant="`outline-${agentBlock.variant}`" :class="`text-${agentBlock.variant}`" class="text-uppercase m-0 opacity-75">
                    <b-icon
                      :class="`text-${agentBlock.variant}`"
                      :icon="agentBlock.icon"
                    />
                    <strong>
                      {{ $t(agentBlock.label) }}
                    </strong>
                  </h3>
                  <span class="smaller-text font-weight-bold ml-1"> Agent Block </span>
                </div>
                
                <div>
                  <small class="text-muted">
                    <span v-if="agentCount(agentBlock) > 0">
                      {{agentCount(agentBlock)}} 
                      <span class="ml-50">
                        Agent<span v-if="agentCount(agentBlock) > 1">s</span>
                      </span>
                    </span>
                    <span v-else>Empty</span>
                  </small>
                  <b-button
                    :id="getID('add-agent')"
                    class="ml-2 middleware-try-catch-btn-scale"
                    size="sm"
                    variant="outline-info"
                    @click="userCantEdit ? '' : selectAgent(agentBlock.id)"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
                <div  v-if="originalAgentOrder[agentBlock.api_tag] != undefined" class="save-agent-order-container">
                  <b-spinner variant="success" small v-if="originalAgentOrder[agentBlock.api_tag] == 'loading'" class="ml-1"/>
                  <b-button class="save-agent-order-btn" variant="none" @click="saveAgentOrder(agentBlock.api_tag)" v-else>
                    <b-icon icon="check"/>
                    Save Order
                  </b-button>
                </div>
              </div>


              <b-collapse class="pl-1 position-relative" :visible="tryCatchShown[index]" :class="tryCatchAgentBlock.length == index + 1 ? 'fade-last-line' : ''" appear>
                <draggable
                  :is=" rearrangementOverlayOff || originalAgentOrder[agentBlock.api_tag] == 'loading' ? 'div' : 'draggable'"
                  class="fit-width-drag"
                  :class="tryCatchAgentBlock.length == index + 1 ? 'fade-last-line' : ''"
                  tag="div"
                  ghostClass="ghost-list-item"
                  v-model="middleware.agents[agentBlock.api_tag]"
                  @change="(itm)=>{listOrderChanged(itm, agentBlock.api_tag)}"
                >
                  <transition-group :key="'agent-spot-container-' + agentSpotVersion" tag="div" :name="!rearrangementOverlayOff ? 'list-reorder' : ''" :class="tryCatchAgentBlock.length == index + 1 ? 'fade-last-line' : ''" class="position-relative fit-width-drag">
                    <template
                      v-for="(item, key) in (middleware.agents ? middleware.agents[agentBlock.api_tag] : [])"
                    >
                      <agent-spot
                        class="position-relative fit-width-drag "
                        v-if="item.enum_agent_action.id"
                        :key="`${versionKey}-${item.id}`"
                        :middlewareID="id"
                        :options="middleware.agents[agentBlock.api_tag][key]"
                        :agentBlockID="agentBlock.id"
                        :timelineVariant="agentBlock.variant"
                        :isDebugMode="checkIfDebugMode(key , middleware.agents[agentBlock.api_tag])"
                        @onDelete="agentDeleted(key, item)"
                        @onEdit="agentEditted"
                      />
                    </template>
                  </transition-group>
                </draggable>
              </b-collapse>
            </div>
          <!-- </draggable> -->
        </app-timeline>
      </b-collapse>

      <slot name="arrow" class="zoom-it" :type="middlewareType">

      </slot>

      <b-collapse
        class="middleware-timeline"
        v-model="middlewaresShown"
      >
        <div v-if="middleware.middlewares && middleware.middlewares.length">
          <inner-middleware-list
            :ref="getID('inner-middleware-list')"
            :key="getID('inner-middleware-list')"
            :direction="directionObj.id"
            :category="category"
            v-model="middleware.middlewares"
            :depth="depth + 1"
            :isShown="middlewaresShown"
            :parent_id="id"
            @deletedMiddlewares="(data) =>{$emit('deletedMiddlewares', data)}"
            @selectMiddleware="selectMiddleware"
            @firstMiddlewareDeleted="adjustNext"
            @middlewaresShownChanged="$emit('middlewaresShownChanged')"
            @adjustMinimap="$emit('adjustMinimap')"
            @refreshMiddlewares="$emit('refreshMiddlewares')"
            @reactiveSpot="$emit('refreshMiddlewares')"
          />
        </div>
      </b-collapse>
    </div>
    
    
    <!-- ============ CREATING AGENT SIDEBAR ============= -->
    <agent-base
      v-if="agent_item && id"
      :component="agent_item.component"
      :key="getID(agentBlockSelected) + newAgentKey"
      :uuid="getID('add-agent-component')"
      :middlewareID="id"
      v-model="agentHolder"
      :agentBlockID="agentBlockSelected"
      @saved="onSaved"
      @agentClosed="setSidebarState(false)"
      @hidden="setSidebarState(false)"
    />
    <!-- ============ CREATING AGENT SIDEBAR ============= -->

    <modal-select-agent
      v-if="allow_add && !no_agents"
      :key="`modal-select-agent-${id}`"
      :uuid="getID('select-agent-type')"
      @selected="setAgentItem"
      @addAgent="addAgent"
    >
      <template #header>
        <div class="d-flex justify-content-around mb-2 mt-50">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            class="mt-0"
            :class="agentBlockSelected == agentBlock.id ? '' : 'inactive-agent-block'"
            v-for="agentBlock in tryCatchAgentBlock"
            :key="agentBlock.id"
            :variant="`${agentBlockSelected == agentBlock.id ? '' : 'outline-'}${agentBlock.variant}`"
            squared
            block
            @click="agentBlockSelection(agentBlock.id)"
          >
            <strong class="text-uppercase">
              {{ $t(agentBlock.label) }}
            </strong>
          </b-button>
        </div>
      </template>
    </modal-select-agent>

    <!-- ============ MIDDLEWARE SIDEBAR ============= -->
    <component
      v-if="middlewareType"
      :key="`sidebar-${versionSidebar}`"
      ref="middleware-sidebar-component"
      :uuid="getID(middlewareType.component)"
      :is="middlewareType.component"
      v-bind="getProps()"
      v-model="middlewareClonedAgents"
      @saved="(list, closeSidebar) => { updateAgentList(list, 'try', closeSidebar) }"
      @hidden="sidebarOpened = false;"
      @close="sidebarOpened = false;"
    />
    <!-- ============ MIDDLEWARE SIDEBAR ============= -->
    
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AgentSpot from "@/layouts/components/Transmission/Middleware/AgentSpot.vue";
import {
  BContainer,
  BCard,
  BCardBody,
  BAvatar,
  BButton,
  BTooltip,
  BFormInput,
  BSkeleton,
  BIcon,
  BCol,
  VBTooltip,
  BCollapse,
  BPopover,
  BForm, 
  BFormGroup, 
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { makeToast } from "@/layouts/components/Popups";
import Agents from "@/custom/class/Enum/Agents";
import { v4 as uuidv4 } from "uuid";
import vSelect from "vue-select";
import AgentsComponents from "@/layouts/components/Transmission/Middleware/Agent/List";
import Ripple from 'vue-ripple-directive'
import Direction from '@/custom/class/Middleware/Direction';
import TryCatchAgentBlock from '@/custom/class/Enum/TryCatch.js'
import Middleware from './class/Middleware';
import MiddlewareType from '@/custom/class/Middleware/Type.js';
import 'animate.css';
import Category from "@/custom/class/Middleware/Category";
import ConnectorLine from './Components/ConnectorLine.vue';
import MiddlewareCard from './Components/MiddlewareCard.vue';
import MiddlewareActions from './Components/MiddlewareActions.vue';
import MiddlewareSidebar from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/index.js'
import DefaultAgent from "@/layouts/components/Transmission/Middleware/Agent/DefaultAgent.js";
import ModalSelectAgent from '@/layouts/components/Transmission/Middleware/Agent/ModalSelectAgent.vue'
import draggable from "vuedraggable";
import AgentBase from "@/layouts/components/Transmission/Middleware/Agent/List/AgentBase.vue"

import TryCatch from '@/custom/class/Enum/TryCatch.js'
import NormalizedAgent from "@/custom/class/NormalizedAgent";
import { errorToast ,successToast } from '@/custom/class/FunctionClasses/CommonToasts';

  export default {
    components: {
      ...AgentsComponents,
      ...MiddlewareSidebar,
      AppTimeline,
      AgentSpot,
      BCard,
      BCardBody,
      BAvatar,
      BButton,
      BTooltip,
      BFormInput,
      vSelect,
      BSkeleton,
      BIcon,
      BCol,
      BCollapse,
      BPopover,
      BForm, 
      BFormGroup, 
      BRow,
      BInputGroup,
      BInputGroupAppend,
      BContainer,
      ConnectorLine,
      MiddlewareCard,
      MiddlewareActions,
      InnerMiddlewareList: () => import('./MiddlewareList'),
      ModalSelectAgent,
      draggable,
      BSpinner,
      AgentBase,
    },
    props: {
      id: {
        type: Number,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      allow_add: {
        type: Boolean,
        default: true,
      },
      middlewareIcon: {
        type: String,
        default: null,
      },
      no_agents: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Object,
        default: undefined,
      },
      depth: {
        type: Number,
        default: 0,
      },
      next: {
        type: Middleware,
        default: undefined,
      },
      previous: {
        type: Middleware,
        default: undefined,
      },
      category: {
        type: Category,
        required: true,
      },
      areMiddlewaresShown: {
        type: Boolean,
        default: false,
      },
    },
    directives: {
      Ripple,
      "b-tooltip": VBTooltip,
    },
    data() {
      return {
        versionKey: uuidv4(),
        timelineShown: false,
        middlewaresShown: this.areMiddlewaresShown,
        tryCatchShown: [true, true, true,],
        uuidMap: {},
        previousName: "",
        middlewareType: null,
        middlewareActionsShown: false,
        middlewarePopoverShown: false,

        middlewareAddNext: null,
        middlewareAddParent: null,
        middlewareAddIndex: this.index,
        agentBlockSelected: null,
        agent_item: undefined,
        middlewareClonedAgents: [],

        versionSidebar: 0,

        agentHolder: undefined,
        middlewareMetadata: new Object(),
        sidebarOpened: false,
        originalAgentOrder:{
          try: undefined,
          then: undefined,
          catch: undefined,
        },
        newAgentKey : 1, 
        agentSpotVersion: 1,
      }
    },
    watch: {
      rearrangementOverlayOff(newValue, oldValue) {
        let ogOrder = structuredClone(this.originalAgentOrder)
        let reorderCanceled = false

        Object.keys(ogOrder).forEach(block => {
          if (ogOrder[block] != undefined && ogOrder[block] != 'loading'){
            this.middleware.agents[block] = ogOrder[block]
            reorderCanceled = true
          }
        });

        if (reorderCanceled){
          makeToast({
            title: "Reordering Canceled",
            text: "Agent Reordering canceled",
            icon: "XIcon",
            variant: "warning",
          })
        }

        this.originalAgentOrder = {
          try: undefined,
          then: undefined,
          catch: undefined,
        }

      },
      sidebarOpened(newValue, oldValue){
        this.$store.dispatch("internal/setSidebarState", newValue)
      },
      fluxSearchMiddlewareStack(newValue, oldValue){
        
        let stack = structuredClone(newValue);
        
        if (stack && stack.includes(this.middleware.id) && !this.middlewaresShown){
          this.toggleMiddlewares()
        }
 
      }
    },
    computed: { 
      middleware: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      ...mapGetters(["rearrangementOverlayOff", "getReorderingMiddlewares"]),
      ...mapGetters("internal" , ['fluxSearchMiddlewareStack']),
      agentsList() {
        const agents = new Agents();
        return agents.items;
      },
      tryCatchAgentBlock() {
        const tryCatch = new TryCatchAgentBlock();
        return tryCatch.items;
      },
      transmissionID() {
        return this.$route.params.transmissionID
      },
      directionObj() {
        return new Direction(this.middleware.enum_direction.id)
      }, 
      userCantEdit(){
        return !(this.$can('create') && this.rearrangementOverlayOff )
      }
    },
    mounted() {
      this.init();
    },
  methods: {
    ...mapGetters(["getCurrentMiddleware"]),
    reactiveMiddleware() {
      this.versionKey = uuidv4();
    },
    init() {
      this.agent_item = this.agentsList[2];

      if (!this.middleware) {
        this.fetchMiddlewareByID()
      } else {
        this.name = this.middleware.name;
      }

      if(!this.middleware.agents){
        this.middleware.agents = {}
      }

      ['try', 'catch', 'then'].forEach(cat => {
        this.middleware.agents[cat] = this.middleware.agents[cat] || []
      });
      
      this.middlewareType = new MiddlewareType(this.middleware.enum_type.id);
      
      this.$nextTick(() => {
        this.identifyMiddlewareOrigin()
      })
    },
    identifyMiddlewareOrigin() {
      this.middleware.agents.try.forEach((el) => {
        if (el.enum_agent_action.id == 0) {
          this.middlewareMetadata = JSON.parse(el.register_1.value)
        }
      })
    },
    isArray(element) {
      return element instanceof Array;
    },
    fetchMiddlewareByID() {
      this.$store.dispatch('getMiddlewareByID', { id: this.id, transmissionID: this.transmissionID})
        .then((data) => {
          this.name = data.name;
          data.middlewares = data.middlewares || [];
          this.middleware = data;
          this.identifyMiddlewareOrigin()

          this.$emit('loading', false)
        })
        .catch(() => {
          makeToast({
            title: this.$t("middleware.toast.load_middleware.error.title"),
            text: this.$t("middleware.toast.load_middleware.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
          this.$emit('loading', false)
        });
    },
    toggleTimeline(state) {
      this.timelineShown = state;
      this.$nextTick(() => {
        this.$emit('adjustMinimap');
      });
    },
    toggleMiddlewares(emit = true) {
      this.middlewaresShown = !this.middlewaresShown;
      if (emit){
        this.$emit('middlewaresShown', this.index);
      }
    },
    toggleTryCatchShown(index) {
      this.$set(this.tryCatchShown, index, !this.tryCatchShown[index])
    },
    addAgent() {
      if (this.agent_item == null) {
        makeToast({
          title: this.$t("middleware.toast.select_agent.error.title"),
          text: this.$t("middleware.toast.select_agent.error.message"),
          variant: "danger",
          icon: "XIcon",
        });
      } else {
        this.setSidebarState(true)
        this.$root.$emit("bv::toggle::collapse", this.getID("add-agent-component"));
      }
    },
    newAgent(item, middleware_id, agent_block) {
      
      return DefaultAgent.setAgent(this, { ...item, agent_block_id: agent_block })
    },
    onSaved(payload) {
      
      this.$root.$emit("bv::toggle::collapse", this.getID("add-agent-component"));

      let obj = payload.response.data;
      obj.middleware_id = this.id;

      if (this.middleware.agents[obj.enum_agent_block.api_tag] == undefined) {
        this.middleware.agents[obj.enum_agent_block.api_tag] = [];
      }
      this.middleware.agents[obj.enum_agent_block.api_tag].push(obj);
      this.$emit('agentAdded', obj)

      this.$nextTick(()=>{
        this.agentSpotVersion++
      })
    },
    agentDeleted(key, agent) {
      this.middleware.agents[agent.enum_agent_block.api_tag].splice(key, 1);
      this.$emit('agentDeleted', agent)

      this.$nextTick(()=>{
        this.agentSpotVersion++
      })
    },
    agentEditted(payload) {
      let e = new TryCatch().items.find(el => el.id == payload.enum_agent_block.id )
      
      let idx = this.middleware.agents[e.api_tag].findIndex(el => el.id == payload.id)
      
      let r = new NormalizedAgent(payload)

      r.register_1 = {
        source:payload.enum_source_register_1,
        value: payload.register_1_value
      }
      r.register_2 = {
        source:payload.enum_source_register_2,
        value: payload.register_2_value
      }
      r.register_destiny = {
        source: payload.enum_source_destiny_id,
        value: payload.destiny_value
      }
      
      this.$set(this.middleware.agents[e.api_tag], idx, r)
      // this.reactiveMiddleware()      
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    selectAgent(id = 1) {
      this.agentBlockSelection(id)
      this.$bvModal.show(this.getID("select-agent-type"));
    },
    async toggleMiddlewareSidebar( wait = false) { //this only opens the sidebar
      this.versionSidebar = this.versionSidebar + 1
      this.middlewareClonedAgents = structuredClone(this.middleware.agents?.try)
      
      await this.$nextTick()

      if (this.$refs['middleware-sidebar-component']){
        this.sidebarOpened = true
      }
      
      let t = 0
      if (wait){
        t = 300
      }
      setTimeout(() => {
        if (this.middlewareType.events){
          this.$root.$emit(
            this.middlewareType.events.show,
            this.getID(this.middlewareType.component)
          )
        } else {
          this.$root.$emit(
            this.getID(this.middlewareType.component)
          )
        }
      }, t);

    },
    updateAgentList(list, agentBlock, closeSidebar=true) {
      if (closeSidebar){
        this.$root.$emit("bv::toggle::collapse", this.getID(this.middlewareType.component))
      }
      
      let idxFound = []

      if(!this.middleware.agents[agentBlock]) this.middleware.agents[agentBlock] = [];

      list.forEach((element) => {
        this.middleware.agents[agentBlock].forEach((agent, agentIdx) => {
          if (element.id == agent.id ) {
            idxFound.push([element, agentIdx])
          }
        })
        if (!idxFound.at(-1) || element.id != idxFound.at(-1)[0].id) this.middleware.agents[agentBlock].push(element)
      })

      idxFound.forEach((element) => {
        this.$set(this.middleware.agents[agentBlock], element[1],  element[0])
      })
      this.reactiveMiddleware()
    },
    getProps() {      
      return this.middlewareType.getProps(this);
    },
    agentBlockSelection(id) {
      this.agentBlockSelected = id;
    },
    agentCount(agentBlock) {
      if (!this.middleware.agents) return 0

      return this.middleware.agents[agentBlock.api_tag] ? this.middleware.agents[agentBlock.api_tag].length : 0 
    },
    setAgentItem(item) {
      this.agent_item = item
      this.newAgentKey = this.newAgentKey + 1
    },
    toggleMiddlewareActions(payload) {
      if (this.timelineShown){
        return
      }
      this.middlewareActionsShown = payload;
    },
    deleteMiddleware() {
      this.$emit('deleteMiddleware', { middleware: this.middleware, type: this.middlewareType })
    },
    adjustNext(middleware) {
      this.middleware.next_id = middleware.next_id;
    },
    selectMiddleware(item){
      if (!item){
        item = {
          depth: this.depth,
          data: this.middleware
        }
      }
      this.$emit('selectMiddleware' , item)
    },
    removeFromMidList(IDs){
      if (this.$refs[this.getID('inner-middleware-list')]){
        this.$refs[this.getID('inner-middleware-list')].removeFromMidList(IDs) 
      }
    },
    checkIfDebugMode(idx, list){
      return !!(list && list[idx] && list[idx-1] && list[idx-1].enum_agent_action.id == 29)
    },
    listOrderChanged(itm, block){
      if (!itm.moved){
        return
      }
      
      let oldList = structuredClone(this.middleware.agents[block])

      oldList.splice(itm.moved.newIndex, 1)
      oldList.splice(itm.moved.oldIndex, 0, itm.moved.element)

      if (this.originalAgentOrder[block] == undefined){
        this.originalAgentOrder[block] = oldList;
      }
    },
    saveAgentOrder(block){
      let list = []
      let idx = 1
      this.middleware.agents[block].forEach(agent => {
        let itm = {id: agent.id, execution_order: idx}
        list.push(itm)
        idx = idx + 1
      });

      let payload = {
        transmissionID: this.transmissionID,
        list: list
      }
      
      this.originalAgentOrder[block] = 'loading'

      this.$store.dispatch('changeAgentsExecutionOrderList', payload)
        .then((resp)=>{
          this.originalAgentOrder[block] = undefined
          successToast({text:"Agent order successfully updated"})
        })
        .catch((err)=>{
          errorToast({})
          console.error(err)
        })
    },
    setSidebarState(state){
      this.$store.dispatch("internal/setSidebarState" , state)
    },
    hideSidebar(){
      this.sidebarOpened = false
    }
  },
}
</script>



<style lang="scss">
.list-reorder-move,
.list-reorder-enter-active,
.list-reorder-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) !important; 
}
</style>

<style lang="scss" scoped>

.middleware-wrapper{
  width: fit-content;
}

.opacity-75{
  opacity: 0.75;
}

.middleware-container {
  .middleware-card {
    max-width: 500px;
    min-width: 350px;
    width: 100%;

    .adjust-top {
      position: relative;
      top: 10px;
    }
  }

  .middleware-timeline {
    margin-left: 100px;
    .fade-last-line {
      > :last-child {
        &::after {
          height: 50%;
          margin-top: 0;
          top: 0;
        }
      }
    }

    .btn-add-agent {
      opacity: 0.6;
      margin-left: 50px;
      max-width: 515px;
      min-width: 300px;
    }
  }
}

.agent-spot-header-size {
  min-width: calc(300px - 1.5rem);
  max-width: calc(550px - 1.5rem);
}

.inactive-agent-block {
  opacity: 0.7;
}

.middleware-try-catch-btn-scale {
  transform: scale(0.9)
}

.agent-block-divider{
  position: relative;
  top: 3px;
  padding: 10px 15px 5px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: solid 1px rgba(97, 97, 97, 0.3);
}

.show-middleware-tab:hover {
  background-color: #283046;
  color: #fff;
}

.show-middleware-tab {
  margin-top: -4px;
  width: 150px;
  font-size: 12px;
  color: #69738f;
  border: solid 3px #283046;
  border-radius: 0px 0px 5px 5px;
  padding: 5px 10px 0px 10px;
  border-top: none;
  z-index: 1;
  transition: all 0.3s ease;
}

.force-disable{
  opacity: 0.5;
  pointer-events: none !important;
  *{
    pointer-events: none !important;
  }
}
.smaller-text{
  font-size: 12px;
  color: rgba(255, 255, 255, 0.25);
}

.ghost-list-item{
  opacity: 0.35;
  filter: grayscale(1);
  background-color: none !important;
}


.save-agent-order-container{
  position: absolute;
  right: -20px;
  transform: translateX(100%);
  width: fit-content;
  .save-agent-order-btn{
    padding: 3px;
    color: var(--success);
    border-radius: 0;
    border-bottom: 2px solid var(--success);
  }
}

.toggle-agents-btn{
  border-radius: 100px;
  padding: 5px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  background-color: #090c14;
}
.middleware-actions-container{
  position: absolute;
  top: 19px;
  transform: translateX(100%);
  right: 0;;
}
</style>

<style lang="scss">
  .fit-width-drag{
    //background-color: #0f1422 !important;
  }
</style>