<template>
  <div>
    <b-input-group class="my-1">
      <b-form-input
        id="search-variable"
        class="bg-default-input"
        placeholder="Search..."
        size="sm"
        v-model="searchItem"
        @input="filterItems"
      />
      <b-input-group-append is-text>
        <feather-icon 
          icon="SearchIcon" 
          class="text-primary" 
        />
      </b-input-group-append>
    </b-input-group>
    <vue-perfect-scrollbar class="border rounded ">
      
      <div class="variables-wrapper py-50 bg-default-input overflow-x-hidden">
        <b-col class="">
          <TransitionGroup name="list" tag="div" class="d-flex flex-wrap">
            <div
              class="m-25 cursor-pointer"
              v-for="(item, index) in internalItems"
              :key="index"
            >
              <drag :transferData="transferData(item, index)">
                <b-badge :variant="variants[index]">
                  {{ index }}
                </b-badge>
              </drag>
            </div>
          </TransitionGroup>
        </b-col>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BBadge,
  BContainer,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import Drag from "./Drag.vue";
import Variable from "../class/Variable";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BBadge,
    BCol,
    BRow,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BContainer,
    BButton,
    BFormTextarea,
    Drag,
    VuePerfectScrollbar,
  },
  props: {
    items: {
      type: undefined,
      required: true,
    },
    variants: {
      type: undefined,
      required: false,
    },
  },
  data() {
    return {
      internalItems: this.items,
      searchItem: "",
    };
  },
  watch: {
    items() {
      this.internalItems = this.items;
      this.searchItem = "";
    }
  },
  methods: {
    transferData(item, index) {
      return new Variable({
        type: "variable",
        value: item,
        index: index,
        variant: this.variants[index],
      });
    },
    filterItems() {
      this.internalItems = Object.keys(this.items)
        .filter((el) => {
          return el.toLowerCase().indexOf(this.searchItem.toLowerCase()) > -1;
        })
        .reduce((res, key) => ((res[key] = this.items[key]), res), {});
    },
  },
};
</script>

<style lang="scss">
.variables-wrapper {
  min-height: 80px;
  height: 80px;
  max-height: 60vh;
  resize: vertical !important;  
  overflow: auto;
}

.bg-default-input {
  background-color: #1f2432 !important;
}

.list-enter-active,
.list-leave-active {
  position: relative;

  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  position: relative;
  opacity: 0;
  transform: translateX(30px);
}

.overflow-x-hidden{
  overflow-x: hidden;
}
</style>