export default {
  MiddlewareJsonRequesterSidebar        : () => import('./JsonRequester.vue'),
  MiddlewareXmlRequesterSidebar         : () => import('./XmlRequester.vue'),
  MiddlewareUrlEncodedRequesterSidebar  : () => import('./UrlEncodedRequester.vue'),
  MiddlewareFormDataRequesterSidebar    : () => import('./FormDataRequester.vue'),
  MiddlewareSoapRequesterSidebar        : () => import('./SoapRequester.vue'),
  MiddlewareDefaultRequesterSidebar     : () => import('./DefaultRequester.vue'),
  MiddlewareForSidebar                  : () => import('./ForSidebar.vue'),
  MiddlewarePropagatorSidebar           : () => import('./PropagatorSidebar.vue'),
  MiddlewareDeniedSidebar               : () => import('./DeniedSidebar.vue'),
  MiddlewareAllowedSidebar              : () => import('./AllowedSidebar.vue'),
  MiddlewareSqlQuerySidebar             : () => import('./MySQL/SQLQuerySidebar.vue'),
  MiddlewareSqlDeleteSidebar            : () => import('./MySQL/SQLDeleteSidebar.vue'),
  MiddlewareSqlInsertSidebar            : () => import('./MySQL/SQLInsertSidebar.vue'),
  MiddlewareSqlSelectSidebar            : () => import('./MySQL/SQLSelectSidebar.vue'),
  MiddlewareSqlUpdateSidebar            : () => import('./MySQL/SQLUpdateSidebar.vue'),
  MiddlewareCustomSidebar               : () => import('./CustomSidebar.vue'),
  MiddlewareWhileSidebar                : () => import('./WhileSidebar.vue'),
  MiddlewareIfSidebar                   : () => import('./IfSidebar.vue'),
  MiddlewareMongoUpdateSidebar          : () => import('./Mongo/UpdateSidebar.vue'),
  MiddlewareMongoFindSidebar            : () => import('./Mongo/FindSidebar.vue'),
  MiddlewareMongoDeleteSidebar          : () => import('./Mongo/DeleteSidebar.vue'),
  MiddlewareMongoInsertSidebar          : () => import('./Mongo/InsertSidebar.vue'),
  MiddlewareFtpFileRetrieverSidebar     : () => import('./FTPFile/FTPFileRetrieverSidebar.vue'),
  MiddlewareFtpStoreFileSidebar         : () => import('./FTPFile/FTPStoreFileSidebar.vue'),
  MiddlewareFtpListFilesSidebar         : () => import('./FTPFile/FTPFileListSidebar.vue'),
  MiddlewareFtpDeleteFileSidebar        : () => import('./FTPFile/FTPDeleteSidebar.vue'),
  MiddlewareFtpFileRenameSidebar        : () => import('./FTPFile/FTPFileRenameSidebar.vue'),
  MiddlewareHtmlEditorSidebar           : () => import('./HtmlEditorSidebar.vue'),
  MiddlewareCodeEditorSidebar           : () => import('./CodeEditorSidebar.vue'),
  MiddlewareStop                        : () => import('./StopSidebar.vue'),
  MiddlewareGoogleSheets                : () => import('./GoogleSheets.vue'),
  MiddlewarePostgresSqlSelect           : () => import('./PostgreSQL/Select.vue'),
  MiddlewarePostgresSqlQuery            : () => import('./PostgreSQL/Query.vue'),
  MiddlewarePostgresSqlDelete           : () => import('./PostgreSQL/Delete.vue'),
  MiddlewarePostgresSqlInsert           : () => import('./PostgreSQL/Insert.vue'),
  MiddlewarePostgresSqlUpdate           : () => import('./PostgreSQL/Update.vue'),
  MiddlewareForeachSidebar              : () => import('./ForeachSidebar.vue'),
  MiddlewareContinueSidebar             : () => import('./ContinueSidebar.vue'),
  MiddlewareXmlSignerSidebar            : () => import('./XmlSignerSidebar.vue'),
  MiddlewareFirebirdQuerySidebar        : () => import('./FirebirdQuery.vue'),
}