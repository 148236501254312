<template>
  <div class="w-100" :class="fullscreen ? 'full-screen' : ''" >
    <div class="flux-toolbar px-2 py-1 d-flex justify-content-between align-items-end position-relative" ref="flux-header-toolbar">
        <div class="emergency-reload-btn small px-1 mx-50 py-50" @click="refreshMiddlewares()" ref="emergency-reload-btn" id="emergency-reload-btn">
          <span>Flux Reload</span>
          <b-icon icon="arrow-repeat" class="ml-50" rotate="45" scale="1.3" />
        </div>
        <b-tooltip target="emergency-reload-btn" triggers="hover">
          CTRL + ESC
        </b-tooltip>

        <div class="d-inline-block text-left highlight-when-shown " :key="'flux-header-category-' + headerVersion" v-if="!isLoading">
          <div class="small text-muted text-uppercase font-weight-bolder">
            <execution-order-helper dir="right" buttonScale="0.7" class="mr-25"/>
            direction: 
            <span class="px-50 py-25 border rounded ml-50 text-light">
              <b-icon :icon="category.direction.icon" class="mr-25"/>
              {{$t(category.direction.label)}}
            </span>
          </div>

          <h3 class="mt-1 mb-0">
            <div v-if="category.identifier == 'free'" class="animate__animated animate__faster animate__fadeIn">
              <b-badge class="px-75 mr-25" variant="light-info" >Free</b-badge>
              <span class="ml-50 text-secondary">Middleware Flux</span>
            </div>
            <div v-if="category.identifier == 'service'" class="animate__animated animate__faster animate__fadeIn">
              <b-badge class="px-75 mr-25" variant="light-primary" >Service</b-badge>
              <span class="ml-50 text-secondary">Middleware Flux</span>
            </div>
            <div v-if="category.identifier == 'event'" class="animate__animated animate__faster animate__fadeIn">
              <b-badge class="px-75 mr-25" variant="light-warning">Event</b-badge>
              <span class="ml-50 text-secondary">Middleware Flux</span>
            </div>
            <div v-if="category.identifier == 'service_event'" class="animate__animated animate__faster animate__fadeIn">
              <b-badge class="px-75 mr-25" variant="light-primary" >Service</b-badge>
              <b-icon icon="plus" class="mr-25" variant="secondary" shift-v="-1"/>
              <b-badge class="px-75 mr-25" variant="light-warning">Event</b-badge>
              <span class="ml-50 text-secondary">Middleware Flux</span>
            </div>
          </h3>
        </div>
        <div v-else>
          <div class="d-flex">
            <b-skeleton height="20px" width="100px" class="mt-0"/>
            <b-skeleton height="20px" width="50px" class="ml-2 mt-0"/>
          </div>
          <b-skeleton height="24px" width="300px" class="m-0"/>
        </div>

      <div class="text-right">
        <b-button class="m-0 p-0 position-relative" variant="none" @click="$refs['flux-searcher'].focusSearch()">
          <span class="border rounded px-50 text-secondary">
            <span class="small font-weight-bold">SEARCH</span>
            <b-icon icon="search" class="ml-25" scale="0.65"/>
          </span>
          <span class="above-btn-hint">Ctrl + Shift + F</span>
        </b-button>
        <b-button class="mx-2  px-1 py-25 position-relative" :variant="internalOverlayOn ? 'secondary': 'outline-secondary'" @click="toggleRearrangementOverlay()">
          <span>REARRANGEMENT OVERLAY</span>
          <span class="above-btn-hint">Ctrl + Space</span>
          <b-icon :icon="internalOverlayOn ? 'grid1x2-fill' : 'grid1x2'  " class="ml-50" scale="0.9" />
        </b-button>

        <b-button size="sm" variant="outline-info" @click="adjustContainerScroll(0, 0)" class="ml-4">
          <b-icon icon="house" />
        </b-button>
        <b-button size="sm" variant="outline-info" @click="changeFullScreen" class="ml-1">
          <b-icon :icon="!fullscreen ? 'arrows-fullscreen' : 'fullscreen-exit'" />
        </b-button>
        
        
        <b-button size="sm" variant="outline-success" @click="adjustMinimapWidth(10)" class="ml-4">
          <b-icon icon="zoom-in" />
        </b-button>
        <b-button size="sm" variant="outline-success" class="ml-1" @click="adjustMinimapWidth(-10)">
          <b-icon icon="zoom-out" />
        </b-button>
        <b-button size="sm" variant="outline-success" class="ml-1" @click="adjustMinimapWidth(mini_map_shown ? -500 : 130)">
          <b-icon :icon="mini_map_shown ? 'eye' : 'eye-slash'" />
        </b-button>
      </div> 
    </div>  

      <div ref="scroll-ancher"/>
   

      <div class="position-relative">
        <div class="flux-searcher-position" :class="rearrangementOverlayOff ? '' : 'overlay-on'">
          <flux-searcher @fluxSearch="fluxSearch" ref="flux-searcher"/>
        </div>
      </div>
      
      <b-container fluid class="p-0 flux-container" :class="hasSidebarOpen ? 'overflow-hidden' : ''" ref="flux-container" @scroll="scrolling" @mousedown="registerClickPosition" @mousemove="moveFluxContainer">
        
        <div v-if="mini_map_shown" class="mini-map" :class="!rearrangementOverlayOff ? 'opacity-0': ''" ref="mini-map" :style="`width: ${mini_map_width}px;`" @mousedown="navigateMinimap">
          <div class="mini-map-marker" ref="mini-map-marker"></div>
          <div class="mini-flux-container" ref="mini-flux-container">
            <div class="mini-flux-content" ref="mini-flux-content"></div>
          </div>
        </div>

        <middleware-list-drag ref="middleware-list-drag"/>

        <div class="mt-2 transition02s" :style="internalOverlayOn ? 'scale:'+zoomOutScale/10 : '' ">
          <div 
            
            id="flux-content"
            class="flux-content"
            ref="flux-content"
            :class="internalOverlayOn ? 'rearrangement-filter' : ''"
          >
            
            <middleware-list
              v-if="!isLoading"
              :key="getID('middleware-list')"
              :ref="midListRef"
              root
              :direction="direction"
              :category="category"
              v-model="middlewareList"

              @middlewaresShownChanged="adjustMinimap"
              @adjustMinimap="adjustMinimap"
              @refreshMiddlewares="refreshMiddlewares"
              @deletedMiddlewares="removeFromMidList"
              @selectMiddleware="userSelectMiddleware"

              :style="`width: max-content; min-width: 100%; padding-right: ${mini_map_width}px;`"
            />

            <div v-else class="middleware-container ml-4 zoom-it">
              <middleware-card-skeleton 
                v-for="i in 4"
                :key="'mid-skeleton-'+ i"
                arrowType="down"
                :arrowWidth="450"
                :hasChildren="false"
                :hideArrow="i==4"
                />
            </div>
          </div>

        </div>
      </b-container>

      <div align="right" v-if="rearrangementOverlayOff">
        <soft-rollback-button :category="category" :middlewares="middlewareList" @forceImport="forceImport"/>
      </div>
      
      <div class="position-relative">
        <transition name="fade">
          <div class="rearrangement-overlay-container" :style="fluxOverlayDinamicStyle()" v-if="internalOverlayOn">
            <rearrangement-overlay
              :initialZoom="parseInt(zoomOutScale)"
              ref="rearrangement-overlay"
              @zoomChanged="updateZoom"
              @paste-to-flux="pasteToFlux"
              @removed="removeFromMidList"
              @initReorder="initReorder"
              @toggleOverlay="toggleRearrangementOverlay"
              @select-all-middlewares="selectAllMiddlewares()"
              @fluxSearch="fluxSearch"
            />
          </div>
        </transition>
      </div>

      <div v-if="bulkAddingMiddlewares">
        <mini-flux-clipboard-on-cursor :middlewares="bulkAddingMiddlewares" @deactivateCursorAttachement="bulkAddingMiddlewares = undefined"/>
      </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BContainer,
  BSkeleton,
  BCollapse,
  BBadge,
  BOverlay,
  BFormInput,
  BTooltip,
  VBTooltip,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Category from "@/custom/class/Middleware/Category";
import Directions from "@/custom/class/Enum/Directions";
import Direction from '@/custom/class/Middleware/Direction';
import HelperTooltip from '@/layouts/components/HelperTooltip'
import Helper from '@/layouts/components/Helper.vue'
import MiddlewareList from './MiddlewareList.vue'
import MiddlewareListDrag from './MiddlewareListDrag.vue'
import { v4 as uuidv4 } from "uuid";
import RearrangementOverlay from '@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/RearrangementOverlay.vue'
import { mapGetters } from 'vuex';
import { makeToast } from "@/layouts/components/Popups";
import miniFluxClipboardOnCursor from '@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/MiniFluxClipboardOnCursor.vue'
import CreateConnectionModal from "./Components/Modals/CreateConnectionModal.vue";
import MiddlewareCardSkeleton from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareCardSkeleton.vue";
import Ripple from "vue-ripple-directive";
import SoftRollbackButton from "@/layouts/components/Transmission/SoftRollbackButton.vue";
import ExecutionOrderHelper from "@/views/pages/middleware/MiddlewareManagement/Components/FluxOverview/ExecutionOrderHelper.vue"
import FluxSearcher from "@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/FluxSearcher.vue"
import Sources from "@/custom/class/Enum/Sources.js"

  export default {
    components: {
      BSkeleton,
      HelperTooltip,
      Helper,
      BContainer,
      BRow,
      BCol,
      BCard,
      BButton,
      BSpinner,
      vSelect,
      MiddlewareList,
      BCollapse,
      MiddlewareListDrag,
      BBadge,
      BOverlay,
      BFormInput,
      RearrangementOverlay,
      BTooltip,
      miniFluxClipboardOnCursor,
      CreateConnectionModal,
      MiddlewareCardSkeleton,
      VBTooltip,
      VBToggle,
      SoftRollbackButton,
      ExecutionOrderHelper,
      FluxSearcher
    },
    directives: {
      ripple: Ripple,
      "b-toggle": VBToggle,
      "b-tooltip": VBTooltip,
    },
    props: {
      value: {
        type: Category,
        required: true,
      },
      direction: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        bulkAddingMiddlewares: undefined,
        categoryCollapseState: false,

        directionObj: new Direction(this.direction),
        middlewareList: undefined,
        uuidMap: {},
        isLoading: false,
        last_mouse_position: null,
        container_scrolling: false,
        container_scroll_speed: 1,

        zoom_max: 2,
        zoom_min: 0.01,
        zoom_step: 0.2,
        current_zoom: 1,

        scroll_counter: 0,
        scroll_distance: undefined,
        fullscreen: false,

        miniMapScale: 1,
        mini_map_width: 250,
        mini_map_shown: false,
        
        pressedKeys:{
          ctrl: false,
          shift: false,
        },
        
        headerVersion: false,
        lastRefreshTimer : undefined,
        internalOverlayOn: false,
        zoomOutScale: 9,
        midListRef: uuidv4(),
      }
    },
    computed: {
      category: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      ...mapGetters(["rearrangementOverlayOff", "getSelectedMiddlewares",]),
      ...mapGetters("internal" , ['hasSidebarOpen']),
      transmissionID() {
        return this.$route.params.transmissionID
      },
      directionList() {
        const dir = new Directions();
        return dir.items;
      },
    },
    mounted() {
      this.refreshMiddlewares(); 
      window.addEventListener('mouseup', this.releaseClickPosition);
      window.addEventListener('keydown', this.registerKey)
      window.addEventListener('keyup', this.unregisterKey)
      window.addEventListener('blur', this.unregisterAllKeys); //this is in case user holds a Key and then changes tab
    },
    destroyed() {
      window.removeEventListener('mouseup', this.releaseClickPosition);
      window.removeEventListener('keydown', this.registerKey)    
      window.removeEventListener('keyup', this.unregisterKey)
      window.removeEventListener('blur', this.unregisterAllKeys);
    },
    watch: {
      category:{
        handler(newValue, oldValue) {
          //changes 'headerVersion' every time the category is updated, forcing the Header to re-mount itself
          //using boolean cause it's lighter on bytes  
          this.headerVersion = !this.headerVersion
        }, deep: true
      },
      hasSidebarOpen:{
        handler(newValue, oldValue){
          let r = newValue ? "hidden" : "auto"

          document.body.style.overflow = r;
        }
      }
    },
    methods: {
      registerKey(e) {
        this.$store.dispatch('internal/fluxKeyDown', e.key.toLowerCase())
        
        //==================================================
        if (e.key.toLowerCase() == 'control') {
          this.is_ctrl_pressed = true
        }
        if (e.key.toLowerCase() == 'shift') {
          this.pressedKeys.shift = true
        }
        //==================================================

        if (this.pressedKeys.ctrl && e.key == 'Escape'){
          this.refreshMiddlewares()
          return
        }

        if(e.key.toLowerCase() == 'r' && this.pressedKeys.shift && this.pressedKeys.ctrl){
          e.preventDefault()
        }

        if(e.key.toLowerCase() == 'f' && this.pressedKeys.ctrl && this.pressedKeys.shift){
          e.preventDefault()
          this.$refs['flux-searcher'].focusSearch()
          return
        }

        if(!this.hasSidebarOpen && !this.internalOverlayOn && e.key.toLowerCase()=='escape'){
          this.$refs['flux-searcher'].clearSearch()
        }
      
        if (!this.hasSidebarOpen){
          
          if(e.key.toLowerCase() == 'r' && this.pressedKeys.shift && this.pressedKeys.ctrl){
            e.preventDefault()
            
            const cat = this.category;

            let triggerParams = {
              senderId: cat.service_id,
              eventId: cat.event_id,
              receiverId: cat.service_id,
            }
            this.$store.dispatch("internal/setTerminalRunTrigger", triggerParams)
            return
          }

          if(e.key.toLowerCase() == ' ' && this.pressedKeys.ctrl){
            e.preventDefault()
            this.toggleRearrangementOverlay()
            return
          }

          if(e.key.toLowerCase() == 'tab' && this.pressedKeys.shift){
            e.preventDefault();             
            this.$refs['middleware-list-drag'].toggleSidebar()
            return
          }

          if (this.internalOverlayOn){
            if (e.key.toLowerCase() == 'escape'){
              this.$refs['rearrangement-overlay'].clearSelection()
              return
            }
            if(e.key.toLowerCase() == 'a' && this.pressedKeys.ctrl){
              e.preventDefault()
              this.selectAllMiddlewares()
              return
            }
            if(e.key.toLowerCase() == 'c' && this.pressedKeys.ctrl){
              this.$refs['rearrangement-overlay'].copy()
              return
            }
            if(e.key.toLowerCase() == 'v' && this.pressedKeys.ctrl){
              this.$refs['rearrangement-overlay'].paste()
              return
            }
            if(e.key.toLowerCase() == 'x' && this.pressedKeys.ctrl){
              this.$refs['rearrangement-overlay'].initReorderingMiddlewares()
              return
            }
            if(e.key.toLowerCase() == 'delete'){
              this.$refs['rearrangement-overlay'].openDeleteModal()
              return
            }
          }

        }
      },
      unregisterKey(e) {
        this.$store.dispatch('internal/fluxKeyUp', e.key.toLowerCase())

        if (e.key == 'Control') {   
          this.pressedKeys.ctrl = false
        }
        if (e.key.toLowerCase() == 'shift') {
          this.pressedKeys.shift = false
        }
      },
      unregisterAllKeys(e){
        this.pressedKeys.ctrl = false
        this.pressedKeys.shift = false
        this.$store.dispatch('internal/unregisterAllKeys', {})
      },
      navigateMinimap(e) {
        var rect = e.target.getBoundingClientRect();
        var x = (e.clientX - rect.left) - 20; //x position within the element.
        var y = (e.clientY - rect.top) - 20;

        var content = this.$refs['flux-content'];
        var container = this.$refs['flux-container'];

        var pct_y = y / (content.clientHeight * this.miniMapScale);

        y = (content.clientHeight * pct_y);
        y -= (container.clientHeight / 2);

        y = Math.max(y, 0);

        var pct_x = x / (content.clientWidth * this.miniMapScale);
        x = (content.clientWidth * pct_x);
        x -= (container.clientWidth / 2);
        x = Math.max(x, 0);

        this.adjustContainerScroll(x, y);
      },
      scrolling(e) {
        var container = this.$refs['flux-container'];
        var content = this.$refs['flux-content'];
        var miniMap = this.$refs['mini-map'];
        var marker = this.$refs['mini-map-marker'];

        if(miniMap){
          // keep minimap on screen
          miniMap.style.top = `${container.scrollTop}px`;
          miniMap.style.right = `${(container.scrollLeft * -1)}px`;

          // adjust marker top
          let top_pct = (container.scrollTop / content.clientHeight);

          let top_px = (top_pct) * (content.clientHeight * this.miniMapScale);

          top_px = Math.min(top_px + 20, miniMap.clientHeight - marker.clientHeight);

          this.$refs['mini-map-marker'].style.top = `${top_px}px`;

          // adjust marker left
          let left_pct = (container.scrollLeft / content.clientWidth);
          let left_px = Math.min(left_pct * this.mini_map_width, miniMap.clientWidth - marker.clientWidth );

          this.$refs['mini-map-marker'].style.left = `${left_px + 20}px`;
        }

      },
      adjustContainerScroll(x, y) {
        var container = this.$refs['flux-container'];
        container.scrollTo(x,y);
      },
      registerClickPosition(e) {
        if(!this.pressedKeys.ctrl) {
          return;
        }

        var x = e.clientX;
        var y = e.clientY;

        this.last_mouse_position = {
          x, y
        };

        this.scroll_counter = 0;
        this.scroll_distance = {
          x: 0,
          y: 0,
        };
        
        document.getElementsByTagName('body')[0].classList.add('cursor-grab-to-all');
      },
      moveFluxContainer(e) {
        if(this.last_mouse_position === null || this.container_scrolling) {
          return;
        }

        this.container_scrolling = true;

        var x = e.clientX;
        var y = e.clientY;

        const dif = {
          x: this.last_mouse_position.x - x,
          y: this.last_mouse_position.y - y,
        };

        this.last_mouse_position = {
          x, y
        };

        this.scroll_distance.x += dif.x;
        this.scroll_distance.y += dif.y;

        var container = this.$refs['flux-container'];
        container.scrollTo(
          container.scrollLeft + (dif.x * this.container_scroll_speed),
          container.scrollTop + (dif.y * this.container_scroll_speed)
        );
        
        this.scroll_counter++;

        setTimeout(() => {
          this.container_scrolling = false;
        }, 20);
      },
      releaseClickPosition() {
        if(this.last_mouse_position === null) {
          return;
        }
        
        document.getElementsByTagName('body')[0].classList.remove('cursor-grab-to-all');

        this.last_mouse_position = null;
        this.container_scroll_speed = 0.8;

        const amount_to_scroll = 250;
        const tol = 150;

        let scroll_x = this.scroll_distance.x;
        if(Math.abs(scroll_x) >= tol) {
          scroll_x = amount_to_scroll;
          
          if(this.scroll_distance.x < 0) {
            scroll_x *= -1;
          }
        }

        let scroll_y = this.scroll_distance.y;
        if(Math.abs(scroll_y) >= tol) {
          scroll_y = amount_to_scroll;
          
          if(this.scroll_distance.y < 0) {
            scroll_y *= -1;
          }
        }

        const distance = {
          x: scroll_x,
          y: scroll_y,
        };

        if(this.scroll_counter <= 7 && Math.abs(distance.x) + Math.abs(distance.y) >= 150) {
          for(var i = 1; i <= 15; i++) {
            let pct = ((16 - i) / 100.0);

            setTimeout(this.smoothScrollEnding, i * 10, distance.x * pct, distance.y * pct);
          }
        }
      },
      smoothScrollEnding(x, y) {
        var container = this.$refs['flux-container'];
        container.scrollTo(
          container.scrollLeft + (x * this.container_scroll_speed),
          container.scrollTop + (y * this.container_scroll_speed)
        );
      },
      refreshMiddlewares() {
        this.$refs['scroll-ancher'].scrollIntoView({behavior: 'smooth', block:'center'});

        this.$refs['flux-searcher'].clearSearch()

        this.isLoading = true
        clearTimeout(this.lastRefreshTimer)
        this.lastRefreshTimer = setTimeout(() => {
          this.directionObj = new Direction(this.direction);
          this.$store
            .dispatch('getAllMiddlewareByCategory', {
              direction_id: this.direction,
              event_id: this.category.event_id,
              service_id: this.category.service_id,
              webhook_id: this.category.webhook_id,
              transmissionID: this.transmissionID,
            })
            .then((response) => {              
              this.middlewareList = this.orderMiddlewares(response);
            })
            .catch((error) => {
              this.isLoading = false

              console.error(error);
            });
        }, 150);
        this.toggleRearrangementOverlay(false);
      },
    orderMiddlewares(midList) {
      let queue = [];
      let inline = [];
      queue.push([ structuredClone(midList) ])
      let current = null;

      while (queue.length > 0) {
        let item = queue.pop()

        Object.keys(item[0]).forEach((key) => {
          if(item[0][key].previous_id == null) {
            current = key;
          }

          inline[key] = item[0][key];

          if (item[0][key].middlewares) {
            queue.push([ item[0][key].middlewares ]);
          }
        })
      }

      let list = [];
      let current_pos = {}
      let idx_arr = {};

      while(current) {

        if(inline[current].parent_id == null) {
          const idx = list.push({ ...inline[current], middlewares: [] }) - 1;
          idx_arr[current] = idx;
          current_pos[current] = list[idx];
        } else {
          const idx = current_pos[inline[current].parent_id].middlewares.push({ ...inline[current], middlewares: [] }) - 1;
          idx_arr[current] = idx;
          current_pos[current] = current_pos[inline[current].parent_id].middlewares[idx];
        }

        current = inline[current].next_id;
      }

      this.isLoading = false;

      this.adjustMinimap();
      return structuredClone(list)
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    addZoom() {
      this.current_zoom += ((this.current_zoom + this.zoom_step) > this.zoom_max) ? 0 : this.zoom_step;
    },
    subZoom() {
      this.current_zoom -= (this.current_zoom - this.zoom_step < this.zoom_min) ? 0 : this.zoom_step;
    },
    resetZoom() {
      this.current_zoom = 1;
    },
    adjustMinimap(should_simulate_scroll = false) {

      setTimeout(() => {
        this.$nextTick(() => {
          let _html;
          if (this.$refs['flux-content']){
            _html = this.$refs['flux-content'].innerHTML;
            _html = this.removeClassElements(_html, 'b-sidebar-outer');
          }

          if (this.$refs['mini-flux-content']){
            this.$refs['mini-flux-content'].innerHTML = _html;
          }

          // adjust mini-map scale and mini-map-marker width
          this.$nextTick(() => {
            
            var container = this.$refs['flux-container'];
            var content = this.$refs['flux-content'];
            var miniMap = this.$refs['mini-map'];
            var miniMapContent = this.$refs['mini-flux-content'];
            var marker = this.$refs['mini-map-marker']
            
            if (miniMap){

              var scaleX = (miniMap.offsetWidth - 40) / content.offsetWidth;
              var scaleY = (miniMap.offsetHeight - 40) / content.offsetHeight;
              var scale = Math.min(scaleY, scaleX);

              this.miniMapScale = scale;

              miniMapContent.style.transform = `scale(${scale})`;

              var markerHeight = container.offsetHeight * scale;
              marker.style.height = `${markerHeight}px`;

              var markerWidth = (container.offsetWidth * scale) - 20;
              marker.style.width = `${markerWidth}px`;

              if(should_simulate_scroll) {
                this.scrolling();
              }
            }
          });
        });
      }, 300) 
    },
    adjustMinimapWidth(dif) {
      if(this.mini_map_width + dif <= 120) {
        this.mini_map_width = 120;
        this.mini_map_shown = false;
        return;
      }

      if(this.mini_map_width + dif > 500) {
        return;
      }

      let simulate_scroll = !this.mini_map_shown;
      this.mini_map_shown = true;

      this.mini_map_width += dif;
      this.adjustMinimap(simulate_scroll);
    },
    removeClassElements(_html, _class) {
      if (!window.DOMParser) return _html;

      var parser = new DOMParser();
      var doc = parser.parseFromString(_html, 'text/html').body;

      var paras = doc.getElementsByClassName(_class);
      
      while(paras[0]) {
          paras[0].remove();
      }

      return doc.outerHTML;
    },
    changeFullScreen() {
      this.fullscreen = !this.fullscreen;
      this.adjustMinimap();
    },
    toggleRearrangementOverlay( forceTo=undefined){
      let r = !this.internalOverlayOn
      if (forceTo != undefined){
        r = forceTo
      }
      this.internalOverlayOn = r
      
      // this.adjustMinimap()
      // this.updateZoom()
      this.$store.dispatch('clearMiddlewareSelection')
      this.$store.dispatch('setAllowMiddlewareEditing', !r)

    },
    selectAllMiddlewares(){
      if (this.middlewareList && this.middlewareList.length > 0){
        this.$store.dispatch("selectAllMiddlewares", this.middlewareList)
      }
    },
    updateZoom(newZoom){
      this.zoomOutScale = newZoom
    },
    fluxOverlayDinamicStyle(){
      let r = ""
      if (this.fullscreen && this.$refs['flux-header-toolbar']){
        r = `
          height: ${window.innerHeight - this.$refs['flux-header-toolbar'].clientHeight}px !important;
          bottom: -15px !important;
          `
      }
      return  r
    },
    userSelectMiddleware(item){
      
      let treated = {
        data: item.data,
        depth: item.depth,
        exception: item.exception,
        shiftPressed: false,
        allMiddlewares: undefined
      }

      if (this.pressedKeys.shift){
        treated.shiftPressed = true
        treated.allMiddlewares = structuredClone(this.middlewareList)
      }

      this.$store.dispatch('userSelectMiddleware', treated)
    },
    pasteToFlux(items){
      this.bulkAddingMiddlewares = items
      this.toggleRearrangementOverlay(false)

      this.$store.dispatch('userCopyMiddlewares', items)
    },
    initReorder(items){
      this.toggleRearrangementOverlay(false)
      this.bulkAddingMiddlewares = items.data
    },
    removeFromMidList(IDs){
      this.$refs[this.midListRef].removeFromMidList(IDs)
    },
    forceImport(compressed){
      this.toggleRearrangementOverlay(true)
      this.$nextTick(()=>{
        this.$refs['rearrangement-overlay'].forceImport(compressed)
      })
    },
    updateRollback(){
      this.$store.dispatch("updateLocalRollback")
    },
    fluxSearch(term , caseSensitive, matchWholeWord){ 

      const searchOpts = {
        caseSensitive: caseSensitive,
        matchWholeWord: matchWholeWord
      }

      // {
      //   middleware: undefined,
      //   agent: undefined,
      //   matchType: -> 'agentSource' | 'agentValue' | 'agentType' | 'middlewareName' | 'middlewareType'
      // }
      let matched = [];

      function matchesSearch(field, searchOpts){
        let r = false;

        if (!searchOpts.caseSensitive){
          term = term.toLowerCase()
          field = field.toLowerCase();
        }

        if (searchOpts.matchWholeWord){
          r = new RegExp("\\b" + term + "\\b").test(field)        
        } else {
          r = String(field).includes(term);  
        }
        
        return r;
      }

      function recursiveSearch(vue, middleware, searchOpts){
        let mid = {
          id: middleware.id,
          name: middleware.name,
          type: vue.$t(middleware.enum_type.label),
          agents: [...middleware.agents.try, ...middleware.agents.then ,...middleware.agents.catch],
          middlewares: middleware.middlewares || []
        }
        middleware = undefined
      
        if (matchesSearch(mid.name, searchOpts)){
          matched.push({
            middleware: mid.id,
            agent: null,
            matchType: 'middlewareName',
            middlewareStack: middlewareStack,
          })
        } else if (matchesSearch(mid.type, searchOpts)){
          matched.push({
            middleware: mid.id,
            agent: null,
            matchType: 'middlewareType',
            middlewareStack: middlewareStack,
          })
        }
        mid.agents.forEach(agent => {
          const type = vue.$t(agent.enum_agent_action.label);

          if (matchesSearch(type, searchOpts)){
            matched.push({
              middleware: mid.id,
              agent: agent.id,
              matchType: 'agentType',
              middlewareStack: middlewareStack,
            })
          }
          const registers =['register_1', 'register_2','register_destiny'] 
          registers.forEach(reg => {
            if (agent[reg] && (agent[reg].value || agent[reg].source)){
              
              if (matchesSearch(agent[reg].value, searchOpts)){
                matched.push({
                  middleware: mid.id,
                  agent: agent.id,
                  matchType: 'agentValue',
                  middlewareStack: middlewareStack,
                })
              }
              const s = new Sources().items.find(el=>el.id == agent[reg].source)
              if (matchesSearch(s.key, searchOpts)){
                matched.push({
                  middleware: mid.id,
                  agent: agent.id,
                  matchType: 'agentSource',
                  middlewareStack: middlewareStack,
                })
              }
            }
          })          
        })

        if (mid.middlewares.length > 0){
          middlewareStack.push(mid.id)
        }
        mid.middlewares.forEach(innerMid => {
          recursiveSearch(vue, innerMid, searchOpts)
        });
      }

      let middlewareStack;
      this.middlewareList.forEach(rootMiddleware => {
        middlewareStack = []
        recursiveSearch(this, rootMiddleware, searchOpts)
      });
      
      
      this.$refs['flux-searcher'].fluxSearchReturn(matched)
    },
  },
}
</script>

<style lang="scss" scoped>

*{--flux-height: 85vh;}

.above-btn-hint{
  position: absolute;
  left: 5px;
  top: -3px;
  transform: translateY(-100%);
  font-size: 11px;
  font-weight: 5000;
  color: var(--secondary) !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


.rearrangement-overlay-container{
  border-radius: 0 0 4px 4px;
  outline: 1px solid rgba(255, 255, 255, 0.1);
  height: var(--flux-height);
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(248, 248, 248, 0.03);
  z-index: 10; 
  pointer-events: none;
  *{
    pointer-events: all
  }
}

.rearrangement-filter{
  filter: grayscale(0.23) ;
}

@keyframes highlight-when-shown{
  0%{
    transform: scale(1);
    filter: brightness(1) saturate(1);
  }
  50%{
    transform: scale(1.08);
    filter: brightness(3) saturate(3);
  }
  100%{
    transform: scale(1);
    filter: brightness(1) saturate(1);
  }
}

.highlight-when-shown{
  animation-name: highlight-when-shown;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  border-radius: 10px;
}
.flux-container {
  height: var(--flux-height);
  min-height: 400px;
  overflow: auto;
}

.direction-select {
  width: 200px;
  position: relative;
}

.transition02s{
  transition: all 0.2s;
}

.flux-searcher-position{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: all 0.3s ease-in-out;

  &.overlay-on{
    top: 60px;
  }
}


</style>

<style lang="scss">

.flux-container {
  position: relative !important;
}

.full-screen {
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw !important;
  height: 100vh !important;

  z-index: 1032;

  background-color: #0f1422;

  .flux-container {
    height: calc(100vh - 105px) !important;
  }

  .mini-map {
    height: calc(100vh - 105px) !important;
  }
}

.flux-toolbar {
  background: #090c14;
  border-bottom: solid 2px #ffffff21;
}

.b-sidebar-outer {
  transform: scale(1) !important;
}

.mini-map {
  height: var(--flux-height);
  min-height: 400px;
  
  position: absolute;
  z-index: 3;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.42);
  border-left: solid 2px #ffffff21;
  width: 150px;

  transition: opacity 0.2s linear;

  .mini-flux-container {
    padding: 20px;
    pointer-events: none;
    max-height: 100%;
  }
}

.mini-flux-content {
  transform-origin: top left;
  transform: scale(0.07);
  width: fit-content;
}

.flux-container {
  scrollbar-width: auto;
  scrollbar-color: #ffffff85 #090c14;
}

/* Chrome, Edge, and Safari */
.flux-container::-webkit-scrollbar {
  width: 13px;
}

.flux-container::-webkit-scrollbar-track {
  background: #090c14;
}

.flux-container::-webkit-scrollbar-thumb {
  background-color: #ffffff85;
  border-radius: 10px;
  border: 3px solid #090c14;
}

.flux-container::-webkit-scrollbar-corner {
  background: #090c14;
}

.flux-content {
  width: max-content;
  min-width: 100%;
}

.mini-map-marker {
  width: 150px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.116);
  position: absolute;

  top: 20px;
  left: 20px;
  z-index: 2;
}

.cursor-grab-to-all {
  * {
    cursor: grabbing !important;
  }
}

.emergency-reload-btn{
  position: absolute;
  opacity: 0.6;
  cursor: pointer;
  right: 0px;
  top: 0;
  color: var(--secondary);
  transition: opacity 0.2s;
  &:hover{
    opacity: 0.9;
  }
}
.opacity-0{
  opacity: 0;
  pointer-events: none;
}

</style>