<template>
  <div class="flux-clipboard-container">

    <div align="right">
      <b-button class="ml-3 py-25 px-7 " variant="outline-light" @click="$refs['import-to-clipboard-modal'].show()">
        <b-icon icon="box-arrow-in-down-left" />
        Import
      </b-button>
    </div>

    <b-collapse :visible="!!(middlewaresInClipboard && middlewaresInClipboard.length)" appear>
      <div class="font-weight-bold mx-50 mt-2 d-flex justify-content-between">
        <div>
          <b-icon icon="clipboard" />
          Clipboard:
        </div>
        <div>
          <b-button class="p-0" variant="none" @click="putInClipboard()">
            <b-icon icon="x"/>
            Clear
          </b-button>
        </div>
      </div>
      
      <div class="position-relative">
        <vue-perfect-scrollbar class="clipboard-body">
          
          <!-- CLIPBOARD =================================== -->
          <div v-if="middlewaresInClipboard && middlewaresInClipboard.length">
            <!-- <drag> -->
              <flux-clipboard-middleware-display :middlewares="middlewaresInClipboard"/>
              <!-- </drag> -->
          </div>
          <!-- CLIPBOARD =================================== -->

        </vue-perfect-scrollbar>
        <b-button class="export-btn px-75 py-25" variant="light" @click="$refs['export-middlewares-modal'].show()">
          <b-icon icon="box-arrow-up-right" />
          Export
        </b-button>
      </div>
      <b-button variant="light" class="paste-from-clipboard-btn" @click="paste()">
        PASTE TO FLUX <span class="small text-secondary position-absolute mx-50">(Ctrl + V)</span>
      </b-button>
    
    </b-collapse>

    <b-modal
      ref="import-to-clipboard-modal"
      centered
      size=""
      hide-footer
      body-class="round"
      header-class="rounded"
    >
      <template #modal-title>
        <div>
          <h5 class="m-0">
            <feather-icon icon="GitPullRequestIcon" />
            Middleware Import
          </h5>
        </div>
      </template>

      <div class="mb-2">
        <span class="mx-25 font-weight-bold">Import from File:</span>
        <b-form-file accept=".fiqon" v-model="importFile" class="">
        </b-form-file>
        <span class="text-secondary small mx-25">
          Ex: Middlewares.fiqon
        </span>
      </div>

      <div align="center">
        <span class="font-weight-bolder">OR</span>
      </div>

      <div>
        <span class="mx-25 font-weight-bold">Import from Text:</span>
        <b-form-textarea v-model.trim="importText"/>
      </div>
      
      <div class="d-flex mt-3">
        <hr class="w-50 mx-1">
        <b-button variant="favorite" class="mx-2 d-flex align-items-center py-50 px-1" :disabled="!(importText || importFile)" @click="importMiddlewares()">
          <b-icon icon="box-arrow-in-down-left" class="mr-50" variant="black"/>
          <span class="text-black">Import</span>
        </b-button>
        <hr class="w-50 mx-1">
      </div>
    </b-modal>


    <modal-export-middlewares ref="export-middlewares-modal" :middlewaresToExport="this.middlewaresInClipboard"/>
</div> 
  
</template>

<script>
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import * as StringCompressor from '@/custom/class/FunctionClasses/StringCompressor.js'
import FluxClipboardMiddlewareDisplay from '@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/FluxClipboardMiddlewareDisplay.vue';
import Drag from '@/views/pages/middleware/MiddlewareManagement/Components/Drag.vue'
import ModalExportMiddlewares from "@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/ModalExportMiddlewares.vue";
import ScriptMiddlewaresDisplay from '@/views/pages/middleware/MiddlewareManagement/Components/FluxOverview/ScriptMiddlewaresDisplay.vue';
 

import {
  BButton,
  BCard,
  BTabs,
  BTab,
  BFormInput,
  BIcon,
  BCollapse,
  BModal,
  BFormFile,
  BFormTextarea,
  BIconstack,
  
} from 'bootstrap-vue'
import { makeToast } from '@/layouts/components/Popups';

export default {
  components: {
    BButton,
    BCard,
    BTabs,
    BTab,
    BFormInput,
    BIcon,
    BCollapse,
    BModal,
    VuePerfectScrollbar,
    BFormFile,
    BFormTextarea,
    FluxClipboardMiddlewareDisplay,
    Drag,
    BIconstack,
    ModalExportMiddlewares,
    ScriptMiddlewaresDisplay
  },
  props: {
  },
  data() {
    return {
      clipboardVisible: false,
      middlewaresInClipboard: undefined,
      importFile: null,
      importText: undefined,
      exportMiddlewareFileName: 'Middlewares',
    }
  },
  computed: {
    ...mapGetters(["getSelectedMiddlewares"]),
  },
  methods: {
    putInClipboard(items){
      this.middlewaresInClipboard = structuredClone(items)
    },
    forceImport(compressed){
      this.getDataFromCompacted(compressed)
    },
    importMiddlewares(){
      if (this.importFile){
        let reader = new FileReader()
        
        reader.onload = e => {
            let text = e.target.result;
            this.getDataFromCompacted(text)
            // this.getDataFromCompacted(text)
        };
        reader.readAsText(this.importFile)  
      } else {
        this.getDataFromCompacted(this.importText)
      }
    },
    getDataFromCompacted(str){
      try{
        let r = StringCompressor.decompressFromBase64(str)
        this.middlewaresInClipboard = JSON.parse(structuredClone(r))
        this.$refs['import-to-clipboard-modal'].hide()
        makeToast({
          title: "Success!",
          text: "Middlewares successfully imported",
          variant: "success",
          icon: "CheckIcon",
        })   
        this.importText = undefined
        this.importFile = undefined
      } catch(err){
        console.error(err)
        makeToast({
          title: "Error!",
          text: "Middlewares coudn't be imported",
          variant: "danger",
          icon: "XIcon",
        })   
      }
    },
    compressedCopiedMiddlewares(){
      let r = JSON.stringify(structuredClone(this.middlewaresInClipboard))
      return StringCompressor.compressToBase64(r)
    },
    copyToClipboard(txt){
      makeToast({
        title: "Success!",
        text: "Copied to Clipboard!",
        variant: "success",
        icon: "CopyIcon",
      })   
      navigator.clipboard.writeText(txt);
    },
    downloadExportFile() {
        let filename = `${this.exportMiddlewareFileName}.fiqon`
        let data = this.compressedCopiedMiddlewares()
        const blob = new Blob([data], {type: 'text/csv'});
        if(window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else{
          const elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;        
          document.body.appendChild(elem);
          elem.click();        
          document.body.removeChild(elem);
        }
        makeToast({
          title: "Downloading...",
          text: `${this.exportMiddlewareFileName}.fiqon started downloading`,
          variant: "secondary",
          icon: "DownloadIcon",
        })   
    },
    paste(){
      if (this.middlewaresInClipboard && this.middlewaresInClipboard.length){
        this.$emit('paste-to-flux' , this.middlewaresInClipboard)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.flux-clipboard-container{
  min-width: 350px;
}
.clipboard-body{
  box-sizing: content-box;
  min-width: 350px;
  min-height: 200px;
  width: 350px;
  height: 200px;
  resize: both;
  border: 2px solid var(--secondary);
  border-radius: 5px;
  padding: 5px;
  background-color: rgba(130, 134, 139,0.1);
  direction: rtl;
  *{
  direction: ltr;
  }

  padding: 25px 0;
  .scrollbar{
    height: 200px;
  }
  overflow: auto;
}
  .export-btn{
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 5px 0 5px 0;
  }

  .fiqon-icon-logo{
    color: red !important;
    position: absolute;
  }
  .exported-file-download-btn{
    cursor: pointer;
    &:hover{
      text-decoration: underline;
      filter: brightness(1.2);
    }
  }
.exported-text-textarea{
  min-height: 100px;
}
  .paste-from-clipboard-btn{
    width: 100%;
    margin-top: 10px;
    letter-spacing: 2px;
    padding: 5px 0;
    border-radius: 5px;
    font-weight: bolder;
  }
</style>