<template>
  <div v-if="$can('create')">
    <div
      class="d-flex border-left border-bottom border-top rounded-left middleware-list-toggler cursor-pointer text-center"  
      :class="!toggle ? 'collapsed' : ''"
      @click="toggleSidebar()"
    >
      <div class="">
        <b-icon
          class="mt-50"
          icon="arrows-angle-contract"
          scale="0.85"
          variant="light"
        />
        <span class="font-weight-bold mt-25 text-light" style="writing-mode: vertical-rl; text-orientation: mixed;">
          Middlewares
        </span>
      </div>
    </div>
    <transition name="tab-overflow">
      <div class="border-left border-top rounded-left tab-overflow cursor-pointer"
        v-if="!toggle"
        @click="toggleSidebar()"
      />
    </transition>
    <transition name="sidebar" appear>
      <b-card no-body class="list-drag-container rounded shadow-lg " v-if="toggle">
        <div class="d-flex align-items-center px-1 pt-50">
          <b-icon
            icon="search"
            scale="1.3"
          />
          <b-form-input 
            class="no-decoration-input" 
            placeholder="Search" 
            size="lg"
            @input="search"
          />
        </div>
        <b-tabs
          justified
          nav-class="m-0 pt-25"
          nav-wrapper-class="pl-0"
        >
          <b-tab class="p-0" v-for="originEnum in middlewareOrigin" :key="`middleware-origin-list-${originEnum.id}`">
            <template #title>
              <b-icon
                :icon="originEnum.icon"
              />
              {{$t(originEnum.label)}}
            </template>
            <vue-perfect-scrollbar class="list-drag-inner-container">
              <component
                :ref="`middleware-list-${originEnum.origin}`"
                :is="`middleware-list-${originEnum.origin}`"
              />
            </vue-perfect-scrollbar>
          </b-tab>
        </b-tabs>
      </b-card>
    </transition>
  </div> 
</template>

<script>
import {
  BButton,
  BCard,
  BTabs,
  BTab,
  BFormInput,
} from 'bootstrap-vue'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import MiddlewareOrigin from '@/custom/class/Enum/MiddlewareOrigin';
import MiddlewareListInternal from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareListByOrigin/Internal.vue'
import MiddlewareListIntegrator from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareListByOrigin/Integrator.vue'

  export default {
    components: {
      BButton,
      VuePerfectScrollbar,
      BCard,
      BTabs,
      BTab,
      BFormInput,
      MiddlewareListIntegrator,
      MiddlewareListInternal
    },
    data() {
      return {
        toggle: false,
        refreshList: false,
        version: 0,
      }
    },
    computed: {
      middlewareOrigin() {
        return new MiddlewareOrigin().items.filter(el => el.active)
      },
    },
    methods: {
      getMiddlewareOrigin(id) {
        return this.middlewareOrigin.find(el => el.id == id)
      },
      search(term) {
        this.middlewareOrigin.forEach((el) => {
          this.$refs[`middleware-list-${el.origin}`][0].search(term)
        })
      },
      toggleSidebar(){
        this.toggle = !this.toggle
      }
    },
  }
</script>

<style lang="scss" scoped>
.middleware-list-toggler {

  box-sizing: content-box;
  z-index: 15;
  position: fixed;
  top: 15%;
  right: 25%;
  height: 130px;
  width: 25px;
  border-color: var(--dark) !important;
  background: var(--dark-blue);
  transition: .5s , padding .1s !important;
  &.collapsed {
    border-color: var(--secondary) !important;
    transition: .45s;
    right: 19px;
  }
  &:hover{
    padding: 3px

  }
}

.tab-overflow {
  z-index: 14;
  position: fixed;
  top: 5%;
  right: 0;
  height: 850px;
  width: 20px;
  border-color: var(--secondary) !important;
  background: #161d31;
  
}



.enum-middleware-list {
  list-style-type: none;
  margin: 5px 0 5px 0;
}

.list-drag-container {
  outline: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 15;
  overflow-x: hidden;
  position: fixed !important;
  width: 25%;
  top: 5%;
  right: 0px;
  border-radius: 5px;
  .list-drag-inner-container {
    height: 85vh;
    border: 1px solid #202638;
    background: #283046;
  }
}
.sidebar-enter-active, .sidebar-leave-active {
  transition: width .5s;
}
.sidebar-enter, .sidebar-leave-to {
  width: 0;
}

.tab-overflow-enter-active, .tab-overflow-leave-active {
  transition: width .5s;
}
.tab-overflow-enter, .tab-overflow-leave-to {
  width: 0;
}
</style>